<!--
// nuxt-ui/components/layout/header/ButtonsNavbar.vue
-->
<script setup lang="ts">
import BaseNuxtLink from "~/nuxt-ui/components/base/baseNuxtLink.vue";
import TicketIcon from "~/nuxt-ui/components/Shared/icons/TicketIcon.vue";
import ShirtIcon from "~/nuxt-ui/components/Shared/icons/ShirtIcon.vue";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import BaseLiveButton from "~/nuxt-ui/components/base/BaseLiveButton.vue";

const localePath = useLocalePath();
const { isKings, isQueens } = useWebContext();
const { region } = useCompetitionStore();

type ButtonData = {
  url: string;
  target: "_blank" | "_self";
};

type Props = {
  live?: boolean;
  store?: boolean;
  tickets?: boolean;
  full?: boolean;
};

withDefaults(defineProps<Props>(), {
  live: true,
});

const ticketsUrl = computed((): ButtonData => {
  if (region && region.ticketsUrl && region.ticketsUrl.includes("https")) {
    return { url: region.ticketsUrl, target: "_blank" };
  } else if (region && region.ticketsUrl && region.ticketsUrl.includes("region")) {
    return {
      url: localePath({
        name: "region-Tickets",
        params: {
          region: region.slug,
        },
      }),
      target: "_self",
    };
  } else {
    return { url: "", target: "_blank" };
  }
});

const storeUrl = computed((): ButtonData => {
  if (region && region.storeUrl && region.storeUrl.includes("https")) {
    return { url: region.storeUrl, target: "_blank" };
  } else if (region && region.storeUrl && region.storeUrl.includes("Stores")) {
    return { url: localePath({ name: "StoresLanding" }), target: "_blank" };
  } else {
    return { url: "", target: "_blank" };
  }
});
</script>

<template>
  <!--  live button-->
  <base-live-button v-if="live"></base-live-button>

  <!--  tickets button-->
  <base-nuxt-link
    v-if="tickets && ticketsUrl.url"
    text-size="text-sm"
    :full-width="full"
    :to="ticketsUrl.url"
    :target="ticketsUrl.target"
    no-prefetch
  >
    <span class="flex items-center gap-2">
      <ticket-icon width="12"></ticket-icon>{{ $t("header.tickets") }}
    </span>
  </base-nuxt-link>

  <!--  store button-->
  <base-nuxt-link
    v-if="store && storeUrl.url"
    text-size="text-sm"
    :full-width="full"
    emphasis
    :to="storeUrl.url"
    :target="storeUrl.target"
    no-prefetch
  >
    <span class="flex items-center gap-2">
      <shirt-icon width="14"></shirt-icon>
      {{ $t("header.store") }}
    </span>
  </base-nuxt-link>
</template>

<style scoped></style>
