<!--
// nuxt-ui/components/layout/footer/GridTeamsShields.vue
-->
<script setup lang="ts">
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { setAliasUrl, slugify } from "~/src/Shared/utils";
import { orderTeamsAlphabetically } from "~/src/team/domain/services/teamService";
import nuxtLink from "#app/components/nuxt-link";

const { currentSeasonTeams, region } = useCompetitionStore();
const { isWorldCup, isRegionHome } = useRegions();

const localePath = useLocalePath();

const activeTeams = currentSeasonTeams.filter(t => !t.isPlaceholder);
const teams = orderTeamsAlphabetically(activeTeams);

// const gridKey = ref(0);
// onUpdated(() => {
//   gridKey.value++;
// });
</script>

<template>
  <section class="py-5 px-4 md:px-0 border-b border-b-primary">
    <!-- for world cup - marquee slider -->
    <div v-if="teams.length && isWorldCup" class="wrapper-slide hide-scrollbar">
      <div class="slide-teams" v-for="i in 2" :key="i">
        <div v-for="team in teams" :key="team.id" class="team-shield">
          <component
            :is="nuxtLink"
            :to="
              localePath({
                name: 'teams-team-teamName',
                params: { team: team.id, teamName: slugify(team.name) },
              })
            "
            class="flex flex-col items-center justify-center"
            :no-prefetch="nuxtLink && isRegionHome ? true : undefined"
          >
            <div class="logo-container">
              <NuxtImg
                v-if="team.logo?.url"
                :src="setAliasUrl(team.logo.url)"
                :alt="team.name"
                class="max-w-full max-h-full"
                height="56"
                width="56"
                loading="lazy"
              />
            </div>
            <p class="team-name">{{ team.shortName }}</p>
          </component>
        </div>
      </div>
    </div>

    <!-- for all others - grid -->
    <div v-if="teams.length && !isWorldCup">
      <div :class="['grid-shields-container kql-w-container', { 'lg:!gap-9': teams.length <= 12 }]">
        <div v-for="team in teams" :key="team.id" class="team-shield">
          <component
            :is="nuxtLink"
            :to="
              localePath({
                name: 'teams-team-teamName',
                params: { team: team.id, teamName: slugify(team.name) },
              })
            "
            class="flex flex-col items-center justify-center"
            :no-prefetch="nuxtLink && isRegionHome ? true : undefined"
          >
            <div class="logo-container">
              <NuxtImg
                v-if="team.logo?.url"
                :src="setAliasUrl(team.logo.url)"
                :alt="team.name"
                class="max-w-full max-h-full"
                height="56"
                width="56"
                loading="lazy"
              />
            </div>
            <p class="team-name">{{ team.shortName }}</p>
          </component>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.grid-shields-container {
  @apply flex flex-wrap justify-center items-center gap-4 md:gap-6;
}

.logo-container {
  @apply w-14 h-14 flex flex-col justify-center items-center;
}

.team-name {
  @apply text-center md:flex justify-center items-center uppercase text-xs text-gray-400 leading-[18px] mt-1 hidden;
}

.wrapper-slide {
  @apply max-w-[1440px] overflow-x-scroll mx-auto flex gap-4 md:gap-6 lg:gap-9;
}

.slide-teams {
  @apply flex justify-start items-center gap-4 md:gap-6 lg:gap-9;
  transform: translateX(0%);
  animation: scrollLeft 65s linear infinite;
  animation-direction: inherit;
  will-change: transform;
}

.wrapper-slide:hover .slide-teams {
  animation-play-state: paused;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0%) translateZ(0);
  }
  100% {
    transform: translateX(-100%) translateZ(0);
  }
}
</style>
