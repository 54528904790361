// nuxt-ui/Shared/composables/links.ts

export function useLinkUtils() {
  const localePath = useLocalePath();
  const { t } = useI18n();

  const setLinkTo = (link: string, region: string) => {
    if (link.includes("http")) {
      return link;
    }

    if (link.startsWith("region") && link.includes("news")) {
      return localePath({ name: link, params: { region: region, news: t("general.news.word") } });
    } else if (link.startsWith("region")) {
      return localePath({
        name: link,
        params: { region: region },
      });
    } else {
      return localePath({
        name: link,
      });
    }
  };

  return {
    setLinkTo,
  };
}
