<!--
// nuxt-ui/components/pages/region-home/TablesBracketHome.vue
-->
<script setup lang="ts">
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import BracketTablesNations from "~/nuxt-ui/components/pages/region-nations/BracketTablesNations.vue";
import SliderArrowsBehaviour from "~/nuxt-ui/components/Shared/slider-matches/SliderArrowsBehaviour.vue";
import { arrowsSliderVisibility } from "~/src/Shared/utils";

const localePath = useLocalePath();
const { regionParam } = useRegions();

onMounted(() => {
  const tablesContainer: HTMLElement | null = document.getElementById("tablesSlider");
  const bracketButtonLeft: HTMLElement | null = document.getElementById("bracketButtonLeft");
  const bracketButtonRight: HTMLElement | null = document.getElementById("bracketButtonRight");

  if (tablesContainer) {
    let childrenTablesContainerWidth: number = 0;
    const childrenTablesContainer = [...tablesContainer.children];
    childrenTablesContainer.forEach(item => (childrenTablesContainerWidth += item.clientWidth));
    const swingWrapper = tablesContainer.querySelector(".swing");

    const stagesBracket: NodeListOf<HTMLElement> = tablesContainer.querySelectorAll(
      ".round-col-container.active-stage"
    );
    const lastStagePlayed: HTMLElement | null = stagesBracket[stagesBracket.length - 1];
    tablesContainer.scrollLeft = 0;

    if (lastStagePlayed && tablesContainer) {
      tablesContainer.scrollLeft = lastStagePlayed.getBoundingClientRect().x - 80;
    }

    if (swingWrapper) {
      if (
        Math.round(tablesContainer.scrollLeft) ===
        Math.round(tablesContainer.scrollWidth) - Math.round(tablesContainer.clientWidth)
      )
        //if is totally right
        swingWrapper.classList.remove("swing");
    }

    tablesContainer.addEventListener("scroll", () => {
      if (swingWrapper) {
        swingWrapper.classList.remove("swing");
      }
      arrowsSliderVisibility("general", tablesContainer, bracketButtonLeft, bracketButtonRight);
    });
  }
});
</script>

<template>
  <div class="bg-gray-900 p-2.5 sm:p-4 rounded-lg">
    <div>
      <div class="justify-between flex text-gray-400 uppercase text-sm mb-2 items-end">
        <div class="flex flex-row gap-2">
          <p>{{ $t("tables.banner.title") }}</p>
        </div>

        <nuxt-link
          :to="
            localePath({
              name: `region-Tables`,
              params: { region: regionParam },
            })
          "
          class="normal-case text-primary text-xs"
        >
          {{ $t("cards.stats.seeMore.link") }}
        </nuxt-link>
      </div>
      <hr />
      <slider-arrows-behaviour
        button-right-id="bracketButtonRight"
        button-left-id="bracketButtonLeft"
        :button-scroll-distance="204"
        slider-container-id="tablesSlider"
        :conditionally-render-if="true"
      >
        <bracket-tables-nations is-home-component></bracket-tables-nations>
      </slider-arrows-behaviour>
    </div>
  </div>
</template>

<style scoped></style>
