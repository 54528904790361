<!--
// nuxt-ui/components/pages/region-home/BrazilLandingHome.vue
-->
<script setup lang="ts">
import HubSpotForm from "~/nuxt-ui/components/pages/region-tickets/HubSpotForm.vue";
import BannerNations from "~/nuxt-ui/components/pages/region-home/BannerNations.vue";

const bgBrazilLanding = "/img/landings/background-landing-brazil.jpg";

const { locale, t } = useI18n();

/*const embedLanguage = ref<string>(locale.value);
const showEmbedTwitch = ref<boolean | undefined>();*/

/*const setEmbedChannelSrc = computed(() => {
  if (embedLanguage.value === "en") {
    return "https://player.twitch.tv/?channel=kingsworld&parent=kingsleague.pro&parent=staging.kingsleague.pro&parent=localhost&muted=true";
  } else if (embedLanguage.value === "it") {
    return "https://player.twitch.tv/?channel=kingsleague_it&parent=kingsleague.pro&parent=staging.kingsleague.pro&parent=localhost&muted=true";
  } else if (embedLanguage.value === "pt") {
    return "https://player.twitch.tv/?channel=kingsleague_br&parent=kingsleague.pro&parent=staging.kingsleague.pro&parent=localhost&muted=true";
  } else {
    return "https://player.twitch.tv/?channel=kingsleague&parent=kingsleague.pro&parent=staging.kingsleague.pro&parent=localhost&muted=true";
  }
});*/

const formId = computed(() => {
  return locale.value === "en"
    ? "45d394e8-67b1-472b-8af8-8996afacb493"
    : "c47d60ef-3426-4452-8936-e6677f8665a3";
});
</script>

<template>
  <banner-nations></banner-nations>
  <section
    class="mx-auto min-h-screen md:min-h-[calc(100vh-225px)] bg-cover bg-right-top bg-no-repeat pt-8 md:py-20"
    :style="`background-image:url('${bgBrazilLanding}');`"
  >
    <div class="max-w-6xl mx-auto">
      <div class="flex flex-col md:flex-row justify-between gap-6 items-center px-4">
        <div class="md:max-w-lg">
          <h1 class="text-primary text-3xl uppercase font-bold font-[termina]">
            {{ $t("landing.brazil.title") }}
          </h1>
          <p class="mt-8">
            {{ $t("landing.brazil.firstSentence") }}
          </p>
          <p>
            {{ $t("landing.brazil.secondSentence") }}
          </p>
          <p class="font-bold">
            {{ $t("landing.italy.signup") }}
          </p>

          <div class="min-h-[378px] max-w-md">
            <hub-spot-form :form-id="formId" src="//js-eu1.hsforms.net/forms/embed/v2.js" />
          </div>
        </div>

        <div class="flex flex-col items-center md:items-start justify-center h-auto max-w-full mb-6 md:mb-0">
          <iframe
            height="350"
            width="100%"
            class="max-w-[771px] max-h-[235px] sm:max-h-none aspect-video rounded-3xl outline-none"
            src="https://www.youtube-nocookie.com/embed/EIZthA6pmFQ?si=uvT21HlvW2ZzaEQE&rel=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
p {
  @apply mb-4;
}
</style>
