<!--
// app.vue
-->
<script setup lang="ts">
import type { Ref } from "@vue/reactivity";
import type { I18nHeadMetaInfo } from "@nuxtjs/i18n";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { initFlowbite } from "flowbite";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";

const competitionStore = useCompetitionStore();
await competitionStore.checkCompetitionStoreState();

const { t, locale, setLocaleCookie } = useI18n();
const { isQueens, isKings, contextOgImage, contextSiteTitle, webContextGenders } = useWebContext();
const { isAmericas } = useRegions();
const route = useRoute();
const {
  public: { onesignalAppId },
} = useRuntimeConfig();

const contextGtmIdTag = isQueens ? "GTM-T2PT3FD" : "GTM-MHC7SV6";
const contextHotjarId = isQueens ? "5156065" : "5149863";
let contextDataLwId: string;
let contextLawwwingSrc: string;

if (isQueens) {
  contextLawwwingSrc =
    "https://cdn.lawwwing.com/widgets/current/034d5612-f2a9-4641-96b2-e58bdb9c41bd/cookie-widget.min.js";
  contextDataLwId = "034d5612-f2a9-4641-96b2-e58bdb9c41bd";
} else {
  contextLawwwingSrc =
    "https://cdn.lawwwing.com/widgets/current/0692ec3a-398a-467c-a160-5d71dcec2412/cookie-widget.min.js";
  contextDataLwId = "0692ec3a-398a-467c-a160-5d71dcec2412";
}

const contextDescription = isQueens ? t("site.description.queens") : t("site.description.kings");

//Double check cookie lang
setLocaleCookie(locale.value || "es");

onMounted(() => {
  initFlowbite();

  // TODO - Publish whe possible. check if double lawwwing cookie and delete them.
  // let e = "lawwwing-consent",
  //   n = decodeURIComponent(document.cookie),
  //   b = n.split("; "),
  //   i = b.filter(n => n.includes(e + "=")),
  //   f = b.filter(n => n.includes(e + "=")).length;
  // if (f > 1)
  //   i.forEach(n => {
  //     n.includes(e) && (document.cookie = `${e}=0; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`);
  //   });
});

const localeHead: Ref<I18nHeadMetaInfo> = useLocaleHead({ addSeoAttributes: true });

const oneSignalScripts = isKings
  ? [
      {
        type: "text/javascript",
        src: "https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js",
        defer: true,
        id: "onesignal",
        tagPriority: "high",
      },
      {
        type: "text/javascript",
        tagPriority: "low",
        id: "onesignal-init",
        innerHTML: `window.OneSignalDeferred = window.OneSignalDeferred || []; window.OneSignalDeferred.push(async function (OneSignal) {await OneSignal.init({ appId: "${onesignalAppId}" });});`,
      },
    ]
  : [];

const sharedScripts: Array<Record<string, any>> = [
  {
    id: "gtmLayer",
    innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${contextGtmIdTag}');`,
    tagPriority: "low",
    key: "gtmScript",
  },
  {
    type: "text/javascript",
    src: contextLawwwingSrc,
    "data-lwid": contextDataLwId,
    tagPriority: "high",
    key: "lwScript",
  },
  {
    type: "text/javascript",
    id: "hs-script-loader",
    async: true,
    defer: true,
    src: "//js-eu1.hs-scripts.com/144680905.js",
    key: "hsScript",
  },
  {
    type: "text/javascript",
    innerHTML: `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:${contextHotjarId},hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`,
    tagPriority: "low",
    key: "hjScript",
  },
];

useHead(
  {
    htmlAttrs: {
      lang: localeHead.value.htmlAttrs!.lang,
    },
    link: () => [...(!!route.params.postSlug || !!route.params.news ? [] : localeHead.value.link || [])],
    titleTemplate: title => {
      return title ? `${title} | ${competitionStore.region.name}` : contextSiteTitle;
    },
    meta: () => [...(localeHead.value.meta || [])],
    script: [...sharedScripts, ...oneSignalScripts],
  },
  { mode: "client" }
);

useSeoMeta({
  ogImage: contextOgImage,
  ogType: "website",
  ogUrl: route.fullPath,
  description: contextDescription,
  ogDescription: contextDescription,
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
