<!--
// nuxt-ui/components/layout/header/Navbar.vue
-->
<script setup lang="ts">
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useWebappStore } from "~/nuxt-ui/stores/webappStore";
import { $TWITCH_INTERVAL_REFRESH } from "~/src/twitch/domain/services/twitchService";
import ContextLeagueLogo from "~/nuxt-ui/components/context-shared/ContextLeagueLogo.vue";
import ContextOtherLeaguesLogo from "~/nuxt-ui/components/context-shared/ContextOtherLeaguesLogo.vue";
import NavCompetition from "~/nuxt-ui/components/layout/header/NavCompetition.vue";
import NavRegions from "~/nuxt-ui/components/layout/header/NavRegions.vue";
import LangSwitcher from "~/nuxt-ui/components/layout/header/LangSwitcher.vue";
import ButtonsNavbar from "~/nuxt-ui/components/layout/header/ButtonsNavbar.vue";
import IconBurgerShowDropbar from "~/nuxt-ui/components/layout/header/IconBurgerShowDropbar.vue";
import DropbarMenuMobile from "~/nuxt-ui/components/layout/header/DropbarMenuMobile.vue";

type Props = {
  toolbarVisible: boolean;
};

withDefaults(defineProps<Props>(), {
  toolbarVisible: true,
});

const competitionStore = useCompetitionStore();
const { webContext, isKings, isQueens } = useWebContext();
const { isKokNations } = useRegions();
const { locale } = useI18n();
const route = useRoute();
const webappStore = useWebappStore();

const showNavMobile = ref<boolean>(false);
const showNavRegionsMobile = ref<boolean>(false);
const intervalId = ref<NodeJS.Timeout>();

const showMobileMenu = () => {
  const theBody: HTMLBodyElement | null = document.querySelector("body");
  showNavMobile.value = !showNavMobile.value;
  if (webappStore.activeRegion !== webappStore.regionHovered) {
    webappStore.regionHovered = webappStore.activeRegion;
  }
  if (theBody) {
    showNavMobile.value
      ? theBody.classList.add("overflow-hidden")
      : theBody.classList.remove("overflow-hidden");
  }
};

const handleBodyClass = () => {
  const theBody: HTMLBodyElement | null = document.querySelector("body");
  if (window.innerWidth > 1199) {
    showNavMobile.value = false;
    showNavRegionsMobile.value = false;
    if (theBody) {
      theBody.classList.remove("overflow-hidden");
    }
  }
};

const clearLiveInterval = () => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
    intervalId.value = undefined;
  }
};

onMounted(async () => {
  window.addEventListener("resize", handleBodyClass);
  await nextTick();

  await webappStore.updateTwitchStatus();
  intervalId.value = setInterval(async function () {
    await webappStore.updateTwitchStatus();
  }, $TWITCH_INTERVAL_REFRESH);
});

onUnmounted(() => {
  clearLiveInterval();
});
</script>

<template>
  <div class="nav-regions" @mouseover="webappStore.hoveringMegaMenu = true">
    <div class="w-[152px]"></div>

    <nav-regions></nav-regions>

    <div class="ml-auto pr-5">
      <lang-switcher button-id="dropdownLangDesktop" dropdown-toggle="dropdownDesktop"></lang-switcher>
    </div>

    <context-other-leagues-logo></context-other-leagues-logo>
  </div>

  <div
    class="nav-competition"
    @mouseover="webappStore.hoveringMegaMenu = true"
    @mouseleave="webappStore.hoveringMegaMenu = false"
  >
    <div class="logo-wrapper" :class="!toolbarVisible ? 'h-full pb-4 lg:h-[91px]' : 'h-full lg:h-[120px]'">
      <context-league-logo
        border-shape
        height="h-full"
        :width="!toolbarVisible ? 'w-[38px] lg:w-[74px]' : 'w-[32px] lg:w-[88px]'"
      />
    </div>

    <nav-competition></nav-competition>

    <icon-burger-show-dropbar :show-nav-mobile="showNavMobile" @show-nav-mobile="showMobileMenu" />
  </div>

  <div class="w-full flex px-4 py-2 items-center justify-start gap-x-2 bg-black lg:hidden">
    <buttons-navbar full live store :tickets="!isKokNations"></buttons-navbar>
  </div>

  <dropbar-menu-mobile v-model:show-nav-mobile="showNavMobile"></dropbar-menu-mobile>
</template>

<style scoped>
.nav-regions {
  @apply w-full pr-6 py-2 max-h-14 justify-start items-center bg-white hidden lg:flex;
}

.nav-competition {
  @apply w-full bg-white lg:bg-black relative z-20 lg:z-auto lg:static border-b-primary border-b px-4 py-2;
  @apply flex justify-start items-center h-[72px] lg:max-h-16 lg:pl-0 lg:py-3.5 lg:pr-6 lg:border-b-0;
}

.logo-wrapper {
  @apply w-auto py-0 flex flex-col items-center justify-center lg:self-end lg:min-w-[152px];
  @apply lg:pt-2 lg:pb-3.5 transition-[height] ease-in-out duration-200;
}
</style>
