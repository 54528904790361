<!--
// nuxt-ui/components/layout/header/LangSwitcher.vue
-->
<script setup lang="ts">
import nuxtLink from "#app/components/nuxt-link";

const { locale, locales, setLocaleCookie } = useI18n();
const error = useError();
const switchLocalePath = useSwitchLocalePath();
const router = useRouter();

const availableLocales = computed(() => {
  return locales.value.filter(i => i.code !== locale.value);
});

type Props = {
  dark?: boolean;
  buttonId: string;
  dropdownToggle: string;
};

defineProps<Props>();

const dropdownPanel = ref<HTMLElement | null>(null);
</script>

<template>
  <button
    v-if="!error || error.statusCode !== 404"
    :id="buttonId"
    :data-dropdown-toggle="dropdownToggle"
    data-dropdown-offset-skidding="-35"
    :class="[
      'font-semibold text-sm py-1 text-center flex items-center',
      dark ? 'text-gray-400' : 'text-gray-500',
    ]"
    type="button"
  >
    {{ locale }}
    <svg
      class="w-2 h-2 ml-1"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 6"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 4 4 4-4"
      />
    </svg>
  </button>

  <!-- Dropdown menu -->
  <div
    ref="dropdownPanel"
    :id="dropdownToggle"
    :class="['z-30 hidden divide-y divide-gray-100 rounded-lg shadow', dark ? 'bg-zinc-900' : 'bg-white']"
  >
    <ul :class="['text-sm font-semibold ']" :aria-labelledby="buttonId">
      <li v-for="avLocale in availableLocales" :key="avLocale.code">
        <component
          :is="!!switchLocalePath(avLocale.code) ? nuxtLink : 'span'"
          :to="switchLocalePath(avLocale.code)"
          :class="[
            'block px-4 py-2 cursor-pointer',
            dark ? 'text-gray-400 hover:text-white' : 'text-gray-500 hover:text-black',
            {
              '!text-gray-300 !cursor-default hover:!text-gray-300 line-through': !switchLocalePath(
                avLocale.code
              ),
            },
          ]"
          no-prefetch
          @click="dropdownPanel?.classList.add('hidden')"
          >{{ avLocale.name }}</component
        >
      </li>
    </ul>
  </div>
</template>

<style scoped></style>
