<!--
// nuxt-ui/components/pages/region-matches/TableRowMatchMatches.vue
-->
<script setup lang="ts">
import type { Match } from "~/src/match/domain/models/Match";
import type { Team } from "~/src/team/domain/models/Team";
import type { DateTimeFormatOptions } from "@intlify/core-base";
import HomeTeam from "~/nuxt-ui/components/pages/region-matches/HomeTeam.vue";
import AwayTeam from "~/nuxt-ui/components/pages/region-matches/AwayTeam.vue";
import matchUiService from "~/src/match/infrastructure/ui-services/matchUiService";
import { useMatches } from "~/nuxt-ui/Shared/composables/season/matches";
import { useTeams } from "~/nuxt-ui/Shared/composables/teams";

type Props = {
  match: Match;
  awayTeam: Team | undefined;
  homeTeam: Team | undefined;
  seasonId?: number;
  timeZone?: string;
  regionParam?: string;
};

const props = defineProps<Props>();

const { locale } = useI18n();
const localePath = useLocalePath();
const route = useRoute();
const { transformStatus } = matchUiService();
const { formatMatchTime } = useMatches();
const { getSeasonTeamPlayers } = useTeams();

const matchTime = formatMatchTime(locale.value, props.match.date, props.regionParam, props.timeZone);

const dateOptions: DateTimeFormatOptions = {
  month: "2-digit",
  day: "numeric",
  year: "2-digit",
  timeZone: props.timeZone,
};

const getWinner = (match: Match): string => {
  if (match.scores.homeScore > match.scores.awayScore) {
    return "home";
  } else if (match.scores.homeScore < match.scores.awayScore) {
    return "away";
  } else {
    if (match.scores.homeScoreP > match.scores.awayScoreP) {
      return "home";
    }
    if (match.scores.homeScoreP < match.scores.awayScoreP) {
      return "away";
    }
  }
};

const showDateTime = time => {
  return (
    time === "0:00h ESP" ||
    time === "12:00 AM ESP" ||
    time === "0:00h CDMX" ||
    time === "12:00 AM CDMX" ||
    time === "00:00h ESP" ||
    time === "00:00h CDMX"
  );
};

const playersHomeTeam =
  props.seasonId && !props.homeTeam?.isPlaceholder
    ? await getSeasonTeamPlayers(props.seasonId, props.homeTeam?.id)
    : undefined;

const playersAwayTeam =
  props.seasonId && !props.homeTeam?.isPlaceholder
    ? await getSeasonTeamPlayers(props.seasonId, props.awayTeam?.id)
    : undefined;
</script>

<template>
  <div class="match-row-container">
    <!--    col 1 lg /hidden sm-->
    <div v-if="match.date" class="hidden lg:block">
      {{ new Date(match.date).toLocaleDateString(locale, dateOptions) }}
    </div>

    <!--    col 2 lg /row 1 col 1 sm-->
    <div class="flex items-center justify-start gap-2">
      <span class="text-xs lg:text-base lg:min-w-24">{{
        match.date && !showDateTime(matchTime) ? matchTime : "-"
      }}</span>
    </div>

    <!--   hidden lg /row 1 col 2 sm-->
    <div class="flex items-center justify-end gap-2 lg:hidden">
      <p v-if="match.status" class="text-xs">{{ $t(transformStatus(match.status)) }}</p>
    </div>

    <!--    col3 lg/ row 2 col 1 sm-->
    <div class="col-span-2 w-full flex items-center justify-between lg:justify-center lg:col-auto">
      <div class="grid grid-cols-[1fr_auto_1fr] lg:gap-x-4 gap-x-1 w-full px-2.5 lg:pl-0 items-center">
        <div>
          <home-team v-if="homeTeam" :team="homeTeam" :isWinner="getWinner(match) === 'home'" is-kok />
          <div v-if="!!playersHomeTeam" class="flex flex-wrap gap-x-2 justify-end">
            <p v-for="player in playersHomeTeam" class="text-xs text-right">{{ player.shortName }}</p>
          </div>
        </div>

        <div class="flex justify-center items-baseline text-white">
          <span
            v-if="typeof match.scores.homeScoreP === 'number'"
            class="font-bold text-sm text-primary mx-1"
          >
            {{ match.scores.homeScoreP }}
          </span>
          <p class="font-bold text-lg" :class="{ 'ml-1': !match.scores.homeScoreP }">
            {{ match.scores.homeScore === null ? "-" : match.scores.homeScore }}
          </p>
          <p class="text-gray-400 text-xs lg:text-sm lg:mx-3.5 mx-1 self-center">VS</p>
          <p class="font-bold text-lg" :class="{ 'mr-1': !match.scores.awayScoreP }">
            {{ match.scores.awayScore === null ? "-" : match.scores.awayScore }}
          </p>
          <span
            v-if="typeof match.scores.awayScoreP === 'number'"
            class="font-bold text-sm text-primary mx-1"
          >
            {{ match.scores.awayScoreP }}
          </span>
        </div>

        <div>
          <away-team v-if="awayTeam" :team="awayTeam" :isWinner="getWinner(match) === 'away'" is-kok />
          <div v-if="playersAwayTeam" class="flex flex-wrap gap-x-2">
            <p v-for="player in playersAwayTeam" class="text-xs">{{ player.shortName }}</p>
          </div>
        </div>
      </div>
    </div>

    <!--    col4-->
    <div class="flex items-center justify-end gap-8">
      <div class="hidden lg:block text-right min-w-14">
        {{ match.status ? $t(transformStatus(match.status)) : "-" }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.match-row-container {
  @apply rounded-lg bg-gray-800 mb-2 items-center px-2 pt-1.5 lg:p-4 w-full grid grid-rows-[auto_1fr] auto-rows-auto grid-cols-[auto_auto] auto-cols-min;
  @apply lg:grid-rows-1 lg:grid-cols-[6rem_auto_1fr_10rem] gap-2 lg:gap-4 text-gray-400;
}
</style>
