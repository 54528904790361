<!--
// nuxt-ui/components/Shared/slider-matches/SliderArrowsBehaviour.vue
-->
<script setup lang="ts">
import { arrowsSliderVisibility } from "~/src/Shared/utils";
import LeftArrow from "~/nuxt-ui/components/Shared/icons/LeftArrow.vue";
import RightArrow from "~/nuxt-ui/components/Shared/icons/RightArrow.vue";

type Props = {
  conditionallyRenderIf: boolean;
  sliderContainerId: string;
  buttonRightId: string;
  buttonLeftId: string;
  buttonScrollDistance: number;
  outerArrows?: boolean;
};

const props = defineProps<Props>();

const slideScroll = (direction: string, speed: number, distance: number, step: number) => {
  const sliderContainer: HTMLElement | null = document.getElementById(props.sliderContainerId);
  const buttonLeftSlider: HTMLElement | null = document.getElementById(props.buttonLeftId);
  const buttonRightSlider: HTMLElement | null = document.getElementById(props.buttonRightId);
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    if (sliderContainer && direction === "left") {
      sliderContainer.scrollLeft -= step;
      arrowsSliderVisibility("left", sliderContainer, buttonLeftSlider, buttonRightSlider);
    } else if (sliderContainer && direction === "right") {
      sliderContainer.scrollLeft += step;
      arrowsSliderVisibility("right", sliderContainer, buttonLeftSlider, buttonRightSlider);
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);
};

onMounted(() => {
  const sliderContainer: HTMLElement | null = document.getElementById(props.sliderContainerId);
  const buttonLeftSlider: HTMLElement | null = document.getElementById(props.buttonLeftId);
  const buttonRightSlider: HTMLElement | null = document.getElementById(props.buttonRightId);

  if (sliderContainer && buttonLeftSlider && buttonRightSlider) {
    sliderContainer.addEventListener("scroll", () => {
      arrowsSliderVisibility("general", sliderContainer, buttonLeftSlider, buttonRightSlider);
    });
  }
});
</script>

<template>
  <div class="relative w-full h-full">
    <div v-if="conditionallyRenderIf" class="hidden md:block">
      <button
        :id="buttonLeftId"
        @click="slideScroll('left', 15, props.buttonScrollDistance, 18)"
        :class="['slide-button-left', outerArrows ? '-left-20 top-1/3' : 'left-6 top-1/2']"
      >
        <left-arrow width="30" height="30"></left-arrow>
      </button>
    </div>
    <slot />
    <div v-if="conditionallyRenderIf" class="hidden md:block">
      <button
        :id="buttonRightId"
        @click="slideScroll('right', 15, props.buttonScrollDistance, 18)"
        :class="['slide-button-right', outerArrows ? '-right-20 top-1/3' : 'right-6 top-1/2']"
      >
        <right-arrow width="30" height="30"></right-arrow>
      </button>
    </div>
  </div>
</template>

<style scoped>
.slide-button-left {
  @apply rounded-full border border-gray-400 p-3.5 text-white absolute z-10 bg-gray-800;
}

.slide-button-right {
  @apply rounded-full border border-gray-400 p-3.5 text-white absolute z-10 bg-gray-800;
}
</style>
