// nuxt-ui/Shared/composables/season/matches.ts

import type { TDateISO } from "~/src/Shared/DateTypes";
import type { DateTimeFormatOptions } from "@intlify/core-base";
import type { UseFetchParams } from "~/src/Shared/Types";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";

interface UseMatches {
  useFetchParamsMatchData: (matchId: number, live: boolean) => UseFetchParams;
  formatMatchTime: (
    locale: string,
    matchDate: TDateISO,
    regionParam: string,
    regionTimeZone: string
  ) => string;
  showDateTime: (time: string) => boolean;
}

export const useMatches = (): UseMatches => {
  return {
    useFetchParamsMatchData,
    formatMatchTime,
    showDateTime,
  };

  function useFetchParamsMatchData(matchId: number, live: boolean): UseFetchParams {
    const { region } = useCompetitionStore();

    const path = `/api/v1/competition/matches/${matchId}`;
    const opts = {
      query: { live, competitionId: region?.competitionId },
    };

    return { path, opts };
  }

  function formatMatchTime(
    locale: string,
    matchDate: TDateISO,
    regionParam: string,
    regionTimeZone: string
  ): string {
    const appendixDate = ref<string>();

    if (regionParam === "espana") {
      appendixDate.value = locale !== "en" ? "h ESP" : " ESP";
    } else if (regionParam === "americas" || regionParam === "world-cup") {
      appendixDate.value = locale !== "en" ? "h CDMX" : " CDMX";
    } else if (regionParam === "kwc-nations") {
      appendixDate.value = locale !== "en" ? "h ITA" : " ITA";
    }

    const dateOptions: DateTimeFormatOptions = {
      timeStyle: "short",
      timeZone: regionTimeZone,
    };

    return new Date(matchDate).toLocaleString(locale, dateOptions) + appendixDate.value;
  }

  function showDateTime(time: string): boolean {
    return (
      time === "0:00h ESP" ||
      time === "12:00 AM ESP" ||
      time === "0:00h CDMX" ||
      time === "12:00 AM CDMX" ||
      time === "00:00h ESP" ||
      time === "00:00h CDMX" ||
      time === "0:00h ITA" ||
      time === "12:00 AM ITA" ||
      time === "00:00h ITA"
    );
  }
};
