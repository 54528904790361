// src/team/domain/services/teamService.ts

import type { Team } from "~/src/team/domain/models/Team";
import { useTeams } from "~/nuxt-ui/Shared/composables/teams";

export const $KINGDOM_TEAMS_IDS = [26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 25];

type OrdinalAbbreviations = "st" | "nd" | "rd" | "th" | "º" | undefined;

export async function teamArrToObjById(teams: Team[], seasonId?: number): Promise<Record<number, Team>> {
  let _teams: Team[] = [];
  try {
    _teams = JSON.parse(JSON.stringify(teams)) as Team[];
  } catch (e) {
    console.error("Error duplicating teams.");
  }

  if (seasonId && seasonId === 13) {
    const { getTeam } = useTeams();
    const redemptionTeamsIdArr: number[] = [5, 8, 9, 10, 37, 38, 40, 41];
    const teamsIds = teams.map(t => t.id);
    const outRedemptionTeamsIds = redemptionTeamsIdArr.filter(id => !teamsIds.includes(id));

    if (outRedemptionTeamsIds.length) {
      for (const teamId of outRedemptionTeamsIds) {
        try {
          const team = await getTeam(teamId);
          if (team) _teams.push(team);
        } catch (e) {
          console.error("Could not fetch out team to object:", e);
        }
      }
    }
  }

  return _teams.reduce(
    (acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    },
    {} as Record<number, Team>
  ); // use "as" to specify the signature
}

export function orderTeamsAlphabetically(currentTeams: Team[]): Team[] {
  return [...currentTeams].sort((a, b) => a.name.localeCompare(b.name));
}

export function assignPositionOrdinalAbbreviation(num: number, lang: string): OrdinalAbbreviations {
  switch (lang) {
    case "en":
      if (num % 10 === 1 && num % 100 !== 11) return "st"; // 1st, 21st, 31st, etc.
      if (num % 10 === 2 && num % 100 !== 12) return "nd"; // 2nd, 22nd, etc.
      if (num % 10 === 3 && num % 100 !== 13) return "rd"; // 3rd, 23rd, etc.
      return "th";

    case "es":
      return "º";

    case "it":
      return "º";

    default:
      return undefined;
  }
}
