<!--
// nuxt-ui/components/pages/region-world-cup/MatchItemBracketTablesNations.vue
-->
<script setup lang="ts">
import type { Match } from "~/src/match/domain/models/Match";
import type { Team } from "~/src/team/domain/models/Team";
import { slugify, setAliasUrl } from "~/src/Shared/utils";

type Props = {
  match: Match | undefined;
  teamsObj: Record<number, Team>;
  nameNoBold?: boolean;
  reducedSize?: boolean;
};

defineProps<Props>();

const localePath = useLocalePath();

const placeholderTeamImg = "/img/nations/shield-nations-placeholder.png";
</script>

<template>
  <div :class="[reducedSize ? 'match-container-small' : 'match-container']">
    <img
      src="/img/nations/match-bg-bracket.png"
      width="228"
      height="50"
      alt="match"
      class="absolute w-full h-full"
    />
    <img
      v-if="match?.participants && match?.participants.homeTeamId !== 58"
      :src="`/img/nations/bg-shields-bracket/${match?.participants.homeTeamId}-left.png`"
      width="104"
      height="51"
      alt="match background left"
      class="absolute left-0 top-0 w-1/2"
    />
    <img
      v-if="match?.participants && match?.participants.awayTeamId !== 58"
      :src="`/img/nations/bg-shields-bracket/${match?.participants.awayTeamId}-right.png`"
      width="104"
      height="51"
      alt="match background right"
      class="absolute right-0 top-0 w-1/2"
    />

    <nuxt-link
      :to="
        !!match
          ? localePath({
              name: 'region-matches-matchId-homeTeam-vs-awayTeam',
              params: {
                region: 'world-cup',
                matchId: match.id,
                homeTeam: slugify(teamsObj[match?.participants.homeTeamId].shortName || ''),
                awayTeam: slugify(teamsObj[match?.participants.awayTeamId].shortName || ''),
              },
            })
          : undefined
      "
      :class="[reducedSize ? 'match-items-container-small' : 'match-items-container']"
    >
      <NuxtImg
        v-if="teamsObj[match?.participants.homeTeamId]"
        :src="
          match?.participants.homeTeamId === 58
            ? placeholderTeamImg
            : setAliasUrl(teamsObj[match?.participants.homeTeamId].logo.url)
        "
        :alt="teamsObj[match?.participants.homeTeamId].shortName"
        width="36"
        height="54"
        :class="['object-contain object-center logo-team', reducedSize ? 'w-11' : 'w-14']"
      />
      <div
        v-if="
          (match?.scores.awayScore || match?.scores.awayScore === 0) &&
          (match.scores.homeScore || match.scores.homeScore === 0) &&
          match.status?.includes('ended')
        "
        :class="['flex justify-center gap-1', reducedSize ? 'mt-3.5 items-baseline' : 'items-center mt-5']"
      >
        <span class="font-bold text-xs">{{ match.scores.homeScoreP }}</span>
        <p :class="['font-semibold', reducedSize ? 'text-xl' : 'text-3xl']">{{ match.scores.homeScore }}</p>
        <img src="/img/nations/kwcn-crown.png" alt="kwcn crown" width="14" height="13" class="w-3 pb-[1px]" />
        <p :class="['font-semibold', reducedSize ? 'text-xl' : 'text-3xl']">{{ match.scores.awayScore }}</p>
        <span class="font-bold text-xs">{{ match.scores.awayScoreP }}</span>
      </div>
      <p v-else :class="['font-semibold mt-4', reducedSize ? 'text-xl' : 'text-2xl']">
        {{ match?.metaInformation?.matchName ? match?.metaInformation?.matchName : "vs" }}
      </p>
      <NuxtImg
        v-if="teamsObj[match?.participants.awayTeamId]"
        :src="
          match?.participants.awayTeamId === 58
            ? placeholderTeamImg
            : setAliasUrl(teamsObj[match?.participants.awayTeamId].logo.url)
        "
        :alt="teamsObj[match?.participants.awayTeamId].shortName"
        width="36"
        height="54"
        :class="['object-contain object-center logo-team', reducedSize ? 'w-11' : 'w-14']"
      />
    </nuxt-link>
  </div>
</template>

<style scoped>
.match-container {
  @apply relative w-56 h-14 transition-all ease-in-out duration-200;
}

.match-container-small {
  @apply relative transition-all ease-in-out duration-200 w-44 h-11;
}

.match-container:hover,
.match-container-small:hover {
  @apply scale-105;
}

.match-items-container {
  @apply flex items-center justify-around p-1 min-w-[228px] gap-1 absolute w-full h-full;
}

.match-items-container-small {
  @apply flex items-center justify-around p-1 gap-1 absolute w-full h-full min-w-[176px];
}
</style>
