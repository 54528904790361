// src/region/domain/service/regionUiService.ts
import type { Region } from "~/src/region/domain/models/Region";
import type { WebContext } from "~/src/Shared/Types";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { getWebContextRegionsData } from "~/src/region/datasources/regionsData";

interface RegionUiService {
  getRegions: (webContext: WebContext) => Promise<Region[]>;
  getWebContextRegions: () => Promise<Region[]>;
  getCurrentRegion: () => Promise<Region | undefined>;
  findRegionInfoByCountryId: (regionCountryId: number, key: string) => any | undefined;
  findRegionInfoByRegionId: (regionId: number, key: string) => any | undefined;
  findRegionInfoByCompetitionId: (competitionId: number, key: string) => any | undefined;
  findRegionInfoBySlug: (slug: string, key: string) => any | undefined;
}

export const $REGION_ROUTE_QUERY_NAME = "region";

const regionUiService = (): RegionUiService => ({
  getRegions,
  getWebContextRegions,
  getCurrentRegion,
  findRegionInfoByCountryId,
  findRegionInfoByRegionId,
  findRegionInfoByCompetitionId,
  findRegionInfoBySlug,
});

export default regionUiService;

async function getRegions(webContext: WebContext): Promise<Region[]> {
  return getWebContextRegionsData(webContext);
}

async function getWebContextRegions(): Promise<Region[]> {
  const { webContext } = useWebContext();
  const regions = await getRegions(webContext);

  if (!regions) throw new Error("Wrong webContext param");

  return regions;
}

async function getCurrentRegion(): Promise<Region | undefined> {
  const route = useRoute();

  if (!route.params.region) return undefined;

  try {
    const regions = await getWebContextRegions();
    if (regions?.length) {
      return regions.find(r => r.slug === route.params.region);
    } else {
      console.error("Current region not found.");
      return undefined;
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

function findRegionInfoByCountryId(countryId: number, key: string): Promise<any | undefined> {
  const { webContext } = useWebContext();
  const _regions = getWebContextRegionsData(webContext);
  const region: Region | undefined = _regions.find(region => region.apiId === countryId);
  return !!region ? region[key] : undefined;
}

function findRegionInfoByRegionId(regionId: number, key: string): Promise<any | undefined> {
  const { webContext } = useWebContext();
  const _regions = getWebContextRegionsData(webContext);
  const region: Region | undefined = _regions.find(region => region.id === regionId);
  return !!region ? region[key] : undefined;
}

function findRegionInfoByCompetitionId(competitionId: number, key: string): Promise<any | undefined> {
  const { webContext } = useWebContext();
  const _regions = getWebContextRegionsData(webContext);
  const region: Region | undefined = _regions.find(region => {
    return !!region.competitionId && region.competitionId?.includes(competitionId);
  });
  return !!region ? region[key] : undefined;
}

function findRegionInfoBySlug(slug: string, key: string): Promise<any | undefined> {
  const { webContext } = useWebContext();
  const _regions = getWebContextRegionsData(webContext);
  const region: Region | undefined = _regions.find(region => region.slug === slug);
  return !!region ? region[key] : undefined;
}
