<!--
// nuxt-ui/components/layout/NavRegions.vue
-->
<script setup lang="ts">
import regionUiService from "~/src/region/infrastructure/ui-services/regionUiService";
import { useWebappStore } from "~/nuxt-ui/stores/webappStore";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";

const localePath = useLocalePath();
const { params } = useRoute();
const { getWebContextRegions } = regionUiService();
const { region } = useCompetitionStore();
const webAppStore = useWebappStore();

const regions = await getWebContextRegions();

const setRegionHoveredValue = (region: string | undefined) => {
  webAppStore.regionHovered = region;
};

onMounted(() => {
  if (!params.region && region) {
    webAppStore.activeRegion = region?.slug;
    webAppStore.regionHovered = region?.slug;
  }
});
</script>

<template>
  <nav>
    <div class="nav-regions" id="navbar-regions">
      <ul class="nav-regions-links-wrapper">
        <li
          v-for="region in regions.filter(r => r.navVisible)"
          :key="region.id"
          :id="`region-${region.slug}`"
          class="nav-regions-item"
          @mouseover="setRegionHoveredValue(region.slug)"
        >
          <a
            :href="localePath({ name: 'region', params: { region: region.slug } })"
            aria-current="page"
            :class="[
              { 'active-region': webAppStore.activeRegion === region.slug },
              {
                'hovered-region': webAppStore.regionHovered === region.slug,
              },
            ]"
            @click="webAppStore.activeRegion = region.slug"
          >
            {{ region.name }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style scoped>
.nav-regions {
  @apply hidden w-full h-14 flex-col justify-center lg:flex lg:w-auto;
}

.nav-regions-links-wrapper {
  @apply flex font-medium text-sm text-black mt-0 border-0 h-full;
}

.nav-regions-item {
  @apply flex flex-col justify-center first:pl-0 first:pr-3 px-3;
}
</style>
