<!--
// nuxt-ui/components/context-shared/ContextLogoLeagueFooter.vue
-->
<script setup lang="ts">
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useWebappStore } from "~/nuxt-ui/stores/webappStore";
import RegionUiService from "~/src/region/infrastructure/ui-services/regionUiService";

const { isWorldCup, isNations, isKokNations, regionParam } = useRegions();
const webappStore = useWebappStore();
const { webContext } = useWebContext();
const { findRegionInfoBySlug } = RegionUiService();

const competitionFooterLogo = regionParam
  ? findRegionInfoBySlug(regionParam, "footerLogo")
  : webappStore.activeRegion
    ? findRegionInfoBySlug(webappStore.activeRegion, "footerLogo")
    : undefined;
</script>

<template>
  <NuxtImg
    v-if="competitionFooterLogo"
    :src="competitionFooterLogo.src"
    :alt="competitionFooterLogo.alt"
    :class="isNations || isWorldCup || isKokNations ? 'logo-kwc' : 'logo'"
    loading="lazy"
    :width="competitionFooterLogo.width"
    :height="competitionFooterLogo.height"
    :sizes="isNations || isWorldCup ? '160px md:220px' : '189px md:312px'"
  />
</template>

<style scoped>
.logo {
  @apply w-[189px] md:w-[312px] h-auto;
}

.logo-kwc {
  @apply w-[160px] md:w-[220px] h-auto;
}
</style>
