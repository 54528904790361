import { default as indexpwBQT78or7Meta } from "/usr/src/app/nuxt-ui/pages/index.vue?macro=true";
import { default as _healthGY28Vty5tPMeta } from "/usr/src/app/nuxt-ui/pages/_health.vue?macro=true";
import { default as _91news_930LdnTnnwqmMeta } from "/usr/src/app/nuxt-ui/pages/[region]/[news].vue?macro=true";
import { default as ContactUsrw2ALuPWDXMeta } from "/usr/src/app/nuxt-ui/pages/[region]/ContactUs.vue?macro=true";
import { default as DraftFyeut1Z096Meta } from "/usr/src/app/nuxt-ui/pages/[region]/Draft.vue?macro=true";
import { default as DraftReservesXslANUcqCwMeta } from "/usr/src/app/nuxt-ui/pages/[region]/DraftReserves.vue?macro=true";
import { default as Faqs5BCDWt1BnNMeta } from "/usr/src/app/nuxt-ui/pages/[region]/Faqs.vue?macro=true";
import { default as indexSYS1Mptx6hMeta } from "/usr/src/app/nuxt-ui/pages/[region]/index.vue?macro=true";
import { default as JoinTheDraftzOD4tCjQpAMeta } from "/usr/src/app/nuxt-ui/pages/[region]/JoinTheDraft.vue?macro=true";
import { default as Matchese3EPrh5KTnMeta } from "/usr/src/app/nuxt-ui/pages/[region]/Matches.vue?macro=true";
import { default as _91matchId_93_45_91homeTeam_93_45vs_45_91awayTeam_93r8EVLWa0ulMeta } from "/usr/src/app/nuxt-ui/pages/[region]/matches/[matchId]-[homeTeam]-vs-[awayTeam].vue?macro=true";
import { default as MercatoAohliHnEBlMeta } from "/usr/src/app/nuxt-ui/pages/[region]/Mercato.vue?macro=true";
import { default as _91post_93WhDAj4jRV3Meta } from "/usr/src/app/nuxt-ui/pages/[region]/news/[post].vue?macro=true";
import { default as _91playerId_93_45_91playerName_93jbxXrbDymKMeta } from "/usr/src/app/nuxt-ui/pages/[region]/players/[playerId]-[playerName].vue?macro=true";
import { default as PlayersDraftYXRk8KN1ZgMeta } from "/usr/src/app/nuxt-ui/pages/[region]/PlayersDraft.vue?macro=true";
import { default as PlayersLegendrVW0i8TZVHMeta } from "/usr/src/app/nuxt-ui/pages/[region]/PlayersLegend.vue?macro=true";
import { default as PlayersVariableOi7xPQqEkqMeta } from "/usr/src/app/nuxt-ui/pages/[region]/PlayersVariable.vue?macro=true";
import { default as KokQualifiersAmericasVMz7CYqc9eMeta } from "/usr/src/app/nuxt-ui/pages/[region]/qualifiers/KokQualifiersAmericas.vue?macro=true";
import { default as KokQualifiersSpainGNdaMdiCArMeta } from "/usr/src/app/nuxt-ui/pages/[region]/qualifiers/KokQualifiersSpain.vue?macro=true";
import { default as Regulation1m89gl8vZTMeta } from "/usr/src/app/nuxt-ui/pages/[region]/Regulation.vue?macro=true";
import { default as SquadSimulatorIxyTLIRxL3Meta } from "/usr/src/app/nuxt-ui/pages/[region]/SquadSimulator.vue?macro=true";
import { default as Stats8UTazvHajLMeta } from "/usr/src/app/nuxt-ui/pages/[region]/Stats.vue?macro=true";
import { default as ApproxTltnBWvarDMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/Approx.vue?macro=true";
import { default as AssistsatH5DADyTYMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/Assists.vue?macro=true";
import { default as BestGoalkeeperSH7oPoYiUnMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/BestGoalkeeper.vue?macro=true";
import { default as CardsiPPsyFH4OLMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/Cards.vue?macro=true";
import { default as DefensivesaHJssfz26tMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/Defensives.vue?macro=true";
import { default as ExpectedjslrSsawzAMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/Expected.vue?macro=true";
import { default as Goalkeeper0BqcoLWEBuMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/Goalkeeper.vue?macro=true";
import { default as Goalsd0JgtlEyalMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/Goals.vue?macro=true";
import { default as GoalsGroupedwpSRhv5M1PMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/GoalsGrouped.vue?macro=true";
import { default as MvptN2cXrfe04Meta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/Mvp.vue?macro=true";
import { default as OffensivesS7E5qrGVzkMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/Offensives.vue?macro=true";
import { default as PassingUngzv2RVsVMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/Passing.vue?macro=true";
import { default as Shots2qDpEEHgoTMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/Shots.vue?macro=true";
import { default as TurnMvpIpvG6VIq1WMeta } from "/usr/src/app/nuxt-ui/pages/[region]/stats/TurnMvp.vue?macro=true";
import { default as Tables3ZeDsG7Cd2Meta } from "/usr/src/app/nuxt-ui/pages/[region]/Tables.vue?macro=true";
import { default as TeamskXCwUdMRqoMeta } from "/usr/src/app/nuxt-ui/pages/[region]/Teams.vue?macro=true";
import { default as _91team_93_45_91teamName_93PpS1Fu5wQQMeta } from "/usr/src/app/nuxt-ui/pages/[region]/teams/[team]-[teamName].vue?macro=true";
import { default as Tickets6F8MqVbZsXMeta } from "/usr/src/app/nuxt-ui/pages/[region]/Tickets.vue?macro=true";
import { default as TierListO44q28siY1Meta } from "/usr/src/app/nuxt-ui/pages/[region]/TierList.vue?macro=true";
import { default as TournamentY4hn8EOKtMMeta } from "/usr/src/app/nuxt-ui/pages/[region]/Tournament.vue?macro=true";
import { default as _91playerId_93_45_91playerName_93GpiEs1faILMeta } from "/usr/src/app/nuxt-ui/pages/players/[playerId]-[playerName].vue?macro=true";
import { default as PlayersLegendzoVeq6IeMpMeta } from "/usr/src/app/nuxt-ui/pages/PlayersLegend.vue?macro=true";
import { default as StoresLandingQGEO7EvhLOMeta } from "/usr/src/app/nuxt-ui/pages/StoresLanding.vue?macro=true";
import { default as _91team_93_45_91teamName_93eRs7wovPZNMeta } from "/usr/src/app/nuxt-ui/pages/teams/[team]-[teamName].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/nuxt-ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "_health___en",
    path: "/en/_health",
    meta: _healthGY28Vty5tPMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/_health.vue").then(m => m.default || m)
  },
  {
    name: "_health___es",
    path: "/es/_health",
    meta: _healthGY28Vty5tPMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/_health.vue").then(m => m.default || m)
  },
  {
    name: "_health___it",
    path: "/it/_health",
    meta: _healthGY28Vty5tPMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/_health.vue").then(m => m.default || m)
  },
  {
    name: "region-news___en",
    path: "/en/:region()/:news()",
    meta: _91news_930LdnTnnwqmMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/[news].vue").then(m => m.default || m)
  },
  {
    name: "region-news___es",
    path: "/es/:region()/:news()",
    meta: _91news_930LdnTnnwqmMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/[news].vue").then(m => m.default || m)
  },
  {
    name: "region-news___it",
    path: "/it/:region()/:news()",
    meta: _91news_930LdnTnnwqmMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/[news].vue").then(m => m.default || m)
  },
  {
    name: "region-ContactUs___en",
    path: "/en/:region()/contact-us",
    meta: ContactUsrw2ALuPWDXMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/ContactUs.vue").then(m => m.default || m)
  },
  {
    name: "region-ContactUs___es",
    path: "/es/:region()/contacto",
    meta: ContactUsrw2ALuPWDXMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/ContactUs.vue").then(m => m.default || m)
  },
  {
    name: "region-ContactUs___it",
    path: "/it/:region()/contatti",
    meta: ContactUsrw2ALuPWDXMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/ContactUs.vue").then(m => m.default || m)
  },
  {
    name: "region-Draft___en",
    path: "/en/:region()/draft",
    meta: DraftFyeut1Z096Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Draft.vue").then(m => m.default || m)
  },
  {
    name: "region-Draft___es",
    path: "/es/:region()/draft",
    meta: DraftFyeut1Z096Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Draft.vue").then(m => m.default || m)
  },
  {
    name: "region-Draft___it",
    path: "/it/:region()/draft",
    meta: DraftFyeut1Z096Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Draft.vue").then(m => m.default || m)
  },
  {
    name: "region-DraftReserves___en",
    path: "/en/:region()/draft/reserves",
    meta: DraftReservesXslANUcqCwMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/DraftReserves.vue").then(m => m.default || m)
  },
  {
    name: "region-DraftReserves___es",
    path: "/es/:region()/draft/reservas",
    meta: DraftReservesXslANUcqCwMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/DraftReserves.vue").then(m => m.default || m)
  },
  {
    name: "region-DraftReserves___it",
    path: "/it/:region()/draft/riserve",
    meta: DraftReservesXslANUcqCwMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/DraftReserves.vue").then(m => m.default || m)
  },
  {
    name: "region-Faqs___en",
    path: "/en/:region()/faqs",
    meta: Faqs5BCDWt1BnNMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Faqs.vue").then(m => m.default || m)
  },
  {
    name: "region-Faqs___es",
    path: "/es/:region()/faqs",
    meta: Faqs5BCDWt1BnNMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Faqs.vue").then(m => m.default || m)
  },
  {
    name: "region-Faqs___it",
    path: "/it/:region()/faqs",
    meta: Faqs5BCDWt1BnNMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Faqs.vue").then(m => m.default || m)
  },
  {
    name: "region___en",
    path: "/en/:region()",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "region___es",
    path: "/es/:region()",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "region___it",
    path: "/it/:region()",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "region-JoinTheDraft___en",
    path: "/en/:region()/join-the-draft",
    meta: JoinTheDraftzOD4tCjQpAMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/JoinTheDraft.vue").then(m => m.default || m)
  },
  {
    name: "region-JoinTheDraft___es",
    path: "/es/:region()/unete-al-draft",
    meta: JoinTheDraftzOD4tCjQpAMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/JoinTheDraft.vue").then(m => m.default || m)
  },
  {
    name: "region-JoinTheDraft___it",
    path: "/it/:region()/join-the-draft",
    meta: JoinTheDraftzOD4tCjQpAMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/JoinTheDraft.vue").then(m => m.default || m)
  },
  {
    name: "region-Matches___en",
    path: "/en/:region()/matches",
    meta: Matchese3EPrh5KTnMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Matches.vue").then(m => m.default || m)
  },
  {
    name: "region-Matches___es",
    path: "/es/:region()/partidos",
    meta: Matchese3EPrh5KTnMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Matches.vue").then(m => m.default || m)
  },
  {
    name: "region-Matches___it",
    path: "/it/:region()/partite",
    meta: Matchese3EPrh5KTnMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Matches.vue").then(m => m.default || m)
  },
  {
    name: "region-matches-matchId-homeTeam-vs-awayTeam___en",
    path: "/en/:region()/matches/:matchId()-:homeTeam()-vs-:awayTeam()",
    meta: _91matchId_93_45_91homeTeam_93_45vs_45_91awayTeam_93r8EVLWa0ulMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/matches/[matchId]-[homeTeam]-vs-[awayTeam].vue").then(m => m.default || m)
  },
  {
    name: "region-matches-matchId-homeTeam-vs-awayTeam___es",
    path: "/es/:region()/partidos/:matchId()-:homeTeam()-vs-:awayTeam()",
    meta: _91matchId_93_45_91homeTeam_93_45vs_45_91awayTeam_93r8EVLWa0ulMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/matches/[matchId]-[homeTeam]-vs-[awayTeam].vue").then(m => m.default || m)
  },
  {
    name: "region-matches-matchId-homeTeam-vs-awayTeam___it",
    path: "/it/:region()/partite/:matchId()-:homeTeam()-vs-:awayTeam()",
    meta: _91matchId_93_45_91homeTeam_93_45vs_45_91awayTeam_93r8EVLWa0ulMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/matches/[matchId]-[homeTeam]-vs-[awayTeam].vue").then(m => m.default || m)
  },
  {
    name: "region-Mercato___en",
    path: "/en/:region()/mercato-transfers",
    meta: MercatoAohliHnEBlMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Mercato.vue").then(m => m.default || m)
  },
  {
    name: "region-Mercato___es",
    path: "/es/:region()/mercato-fichajes",
    meta: MercatoAohliHnEBlMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Mercato.vue").then(m => m.default || m)
  },
  {
    name: "region-news-post___en",
    path: "/en/:region()/news/:postSlug()",
    meta: _91post_93WhDAj4jRV3Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/news/[post].vue").then(m => m.default || m)
  },
  {
    name: "region-news-post___es",
    path: "/es/:region()/actualidad/:postSlug()",
    meta: _91post_93WhDAj4jRV3Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/news/[post].vue").then(m => m.default || m)
  },
  {
    name: "region-news-post___it",
    path: "/it/:region()/notizie/:postSlug()",
    meta: _91post_93WhDAj4jRV3Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/news/[post].vue").then(m => m.default || m)
  },
  {
    name: "region-players-playerId-playerName___en",
    path: "/en/:region()/players/:playerId()-:playerName()",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/players/[playerId]-[playerName].vue").then(m => m.default || m)
  },
  {
    name: "region-players-playerId-playerName___es",
    path: "/es/:region()/jugadores/:playerId()-:playerName()",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/players/[playerId]-[playerName].vue").then(m => m.default || m)
  },
  {
    name: "region-players-playerId-playerName___it",
    path: "/it/:region()/giocatori/:playerId()-:playerName()",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/players/[playerId]-[playerName].vue").then(m => m.default || m)
  },
  {
    name: "region-PlayersDraft___en",
    path: "/en/:region()/draft-players",
    meta: PlayersDraftYXRk8KN1ZgMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/PlayersDraft.vue").then(m => m.default || m)
  },
  {
    name: "region-PlayersDraft___es",
    path: "/es/:region()/jugadores-draft",
    meta: PlayersDraftYXRk8KN1ZgMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/PlayersDraft.vue").then(m => m.default || m)
  },
  {
    name: "region-PlayersDraft___it",
    path: "/it/:region()/giocatori-draft",
    meta: PlayersDraftYXRk8KN1ZgMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/PlayersDraft.vue").then(m => m.default || m)
  },
  {
    name: "region-PlayersLegend___en",
    path: "/en/:region()/legends",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/PlayersLegend.vue").then(m => m.default || m)
  },
  {
    name: "region-PlayersLegend___es",
    path: "/es/:region()/leyendas",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/PlayersLegend.vue").then(m => m.default || m)
  },
  {
    name: "region-PlayersLegend___it",
    path: "/it/:region()/leggende",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/PlayersLegend.vue").then(m => m.default || m)
  },
  {
    name: "region-PlayersVariable___en",
    path: "/en/:region()/11-12-13-players",
    meta: PlayersVariableOi7xPQqEkqMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/PlayersVariable.vue").then(m => m.default || m)
  },
  {
    name: "region-PlayersVariable___es",
    path: "/es/:region()/jugadores-11-12-13",
    meta: PlayersVariableOi7xPQqEkqMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/PlayersVariable.vue").then(m => m.default || m)
  },
  {
    name: "region-PlayersVariable___it",
    path: "/it/:region()/11-12-13-giocatori",
    meta: PlayersVariableOi7xPQqEkqMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/PlayersVariable.vue").then(m => m.default || m)
  },
  {
    name: "region-qualifiers-KokQualifiersAmericas___en",
    path: "/en/:region()/qualifiers/americas",
    meta: KokQualifiersAmericasVMz7CYqc9eMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/qualifiers/KokQualifiersAmericas.vue").then(m => m.default || m)
  },
  {
    name: "region-qualifiers-KokQualifiersAmericas___es",
    path: "/es/:region()/qualifiers/americas",
    meta: KokQualifiersAmericasVMz7CYqc9eMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/qualifiers/KokQualifiersAmericas.vue").then(m => m.default || m)
  },
  {
    name: "region-qualifiers-KokQualifiersAmericas___it",
    path: "/it/:region()/qualifiers/americas",
    meta: KokQualifiersAmericasVMz7CYqc9eMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/qualifiers/KokQualifiersAmericas.vue").then(m => m.default || m)
  },
  {
    name: "region-qualifiers-KokQualifiersSpain___en",
    path: "/en/:region()/qualifiers/spain",
    meta: KokQualifiersSpainGNdaMdiCArMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/qualifiers/KokQualifiersSpain.vue").then(m => m.default || m)
  },
  {
    name: "region-qualifiers-KokQualifiersSpain___es",
    path: "/es/:region()/qualifiers/spain",
    meta: KokQualifiersSpainGNdaMdiCArMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/qualifiers/KokQualifiersSpain.vue").then(m => m.default || m)
  },
  {
    name: "region-qualifiers-KokQualifiersSpain___it",
    path: "/it/:region()/qualifiers/spain",
    meta: KokQualifiersSpainGNdaMdiCArMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/qualifiers/KokQualifiersSpain.vue").then(m => m.default || m)
  },
  {
    name: "region-Regulation___en",
    path: "/en/:region()/regulation",
    meta: Regulation1m89gl8vZTMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Regulation.vue").then(m => m.default || m)
  },
  {
    name: "region-Regulation___es",
    path: "/es/:region()/reglamento",
    meta: Regulation1m89gl8vZTMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Regulation.vue").then(m => m.default || m)
  },
  {
    name: "region-Regulation___it",
    path: "/it/:region()/regolamento",
    meta: Regulation1m89gl8vZTMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Regulation.vue").then(m => m.default || m)
  },
  {
    name: "region-SquadSimulator___en",
    path: "/en/:region()/squad-simulator",
    meta: SquadSimulatorIxyTLIRxL3Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/SquadSimulator.vue").then(m => m.default || m)
  },
  {
    name: "region-SquadSimulator___es",
    path: "/es/:region()/simulador-plantilla",
    meta: SquadSimulatorIxyTLIRxL3Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/SquadSimulator.vue").then(m => m.default || m)
  },
  {
    name: "region-SquadSimulator___it",
    path: "/it/:region()/squad-simulator",
    meta: SquadSimulatorIxyTLIRxL3Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/SquadSimulator.vue").then(m => m.default || m)
  },
  {
    name: "region-Stats___en",
    path: "/en/:region()/stats",
    meta: Stats8UTazvHajLMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Stats.vue").then(m => m.default || m)
  },
  {
    name: "region-Stats___es",
    path: "/es/:region()/estadisticas",
    meta: Stats8UTazvHajLMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Stats.vue").then(m => m.default || m)
  },
  {
    name: "region-Stats___it",
    path: "/it/:region()/statistiche",
    meta: Stats8UTazvHajLMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Stats.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Approx___en",
    path: "/en/:region()/stats/Approx",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Approx.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Approx___es",
    path: "/es/:region()/stats/Approx",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Approx.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Approx___it",
    path: "/it/:region()/stats/Approx",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Approx.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Assists___en",
    path: "/en/:region()/stats/assists",
    meta: AssistsatH5DADyTYMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Assists.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Assists___es",
    path: "/es/:region()/estadisticas/asistencias",
    meta: AssistsatH5DADyTYMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Assists.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Assists___it",
    path: "/it/:region()/statistiche/assist",
    meta: AssistsatH5DADyTYMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Assists.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-BestGoalkeeper___en",
    path: "/en/:region()/stats/best-goalkeeper",
    meta: BestGoalkeeperSH7oPoYiUnMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/BestGoalkeeper.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-BestGoalkeeper___es",
    path: "/es/:region()/estadisticas/mejor-portero",
    meta: BestGoalkeeperSH7oPoYiUnMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/BestGoalkeeper.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-BestGoalkeeper___it",
    path: "/it/:region()/statistiche/miglior-portiere",
    meta: BestGoalkeeperSH7oPoYiUnMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/BestGoalkeeper.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Cards___en",
    path: "/en/:region()/stats/cards",
    meta: CardsiPPsyFH4OLMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Cards.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Cards___es",
    path: "/es/:region()/estadisticas/tarjetas",
    meta: CardsiPPsyFH4OLMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Cards.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Cards___it",
    path: "/it/:region()/statistiche/cartellini",
    meta: CardsiPPsyFH4OLMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Cards.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Defensives___en",
    path: "/en/:region()/stats/defensives",
    meta: DefensivesaHJssfz26tMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Defensives.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Defensives___es",
    path: "/es/:region()/estadisticas/defensivas",
    meta: DefensivesaHJssfz26tMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Defensives.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Defensives___it",
    path: "/it/:region()/statistiche/difensive",
    meta: DefensivesaHJssfz26tMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Defensives.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Expected___en",
    path: "/en/:region()/stats/expected",
    meta: ExpectedjslrSsawzAMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Expected.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Expected___es",
    path: "/es/:region()/estadisticas/expected",
    meta: ExpectedjslrSsawzAMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Expected.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Expected___it",
    path: "/it/:region()/statistiche/gol-previsti",
    meta: ExpectedjslrSsawzAMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Expected.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Goalkeeper___en",
    path: "/en/:region()/stats/goalkeeper",
    meta: Goalkeeper0BqcoLWEBuMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Goalkeeper.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Goalkeeper___es",
    path: "/es/:region()/estadisticas/portero",
    meta: Goalkeeper0BqcoLWEBuMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Goalkeeper.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Goalkeeper___it",
    path: "/it/:region()/statistiche/portiere",
    meta: Goalkeeper0BqcoLWEBuMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Goalkeeper.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Goals___en",
    path: "/en/:region()/stats/goals",
    meta: Goalsd0JgtlEyalMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Goals.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Goals___es",
    path: "/es/:region()/estadisticas/goles",
    meta: Goalsd0JgtlEyalMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Goals.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Goals___it",
    path: "/it/:region()/statistiche/gol",
    meta: Goalsd0JgtlEyalMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Goals.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-GoalsGrouped___en",
    path: "/en/:region()/stats/goals-grouped",
    meta: GoalsGroupedwpSRhv5M1PMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/GoalsGrouped.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-GoalsGrouped___es",
    path: "/es/:region()/estadisticas/goles-agrupado",
    meta: GoalsGroupedwpSRhv5M1PMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/GoalsGrouped.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-GoalsGrouped___it",
    path: "/it/:region()/statistiche/gol-a",
    meta: GoalsGroupedwpSRhv5M1PMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/GoalsGrouped.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Mvp___en",
    path: "/en/:region()/stats/mvp",
    meta: MvptN2cXrfe04Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Mvp.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Mvp___es",
    path: "/es/:region()/estadisticas/mvp",
    meta: MvptN2cXrfe04Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Mvp.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Mvp___it",
    path: "/it/:region()/statistiche/mvp",
    meta: MvptN2cXrfe04Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Mvp.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Offensives___en",
    path: "/en/:region()/stats/offensives",
    meta: OffensivesS7E5qrGVzkMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Offensives.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Offensives___es",
    path: "/es/:region()/estadisticas/ofensivas",
    meta: OffensivesS7E5qrGVzkMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Offensives.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Offensives___it",
    path: "/it/:region()/statistiche/offensive",
    meta: OffensivesS7E5qrGVzkMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Offensives.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Passing___en",
    path: "/en/:region()/stats/passing",
    meta: PassingUngzv2RVsVMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Passing.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Passing___es",
    path: "/es/:region()/estadisticas/pases",
    meta: PassingUngzv2RVsVMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Passing.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Passing___it",
    path: "/it/:region()/statistiche/passaggi",
    meta: PassingUngzv2RVsVMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Passing.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Shots___en",
    path: "/en/:region()/stats/shots",
    meta: Shots2qDpEEHgoTMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Shots.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Shots___es",
    path: "/es/:region()/estadisticas/tiros",
    meta: Shots2qDpEEHgoTMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Shots.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-Shots___it",
    path: "/it/:region()/statistiche/tiri",
    meta: Shots2qDpEEHgoTMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/Shots.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-TurnMvp___en",
    path: "/en/:region()/stats/matchday-mvp",
    meta: TurnMvpIpvG6VIq1WMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/TurnMvp.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-TurnMvp___es",
    path: "/es/:region()/estadisticas/mvp-jornada",
    meta: TurnMvpIpvG6VIq1WMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/TurnMvp.vue").then(m => m.default || m)
  },
  {
    name: "region-stats-TurnMvp___it",
    path: "/it/:region()/statistiche/mvp-giornata",
    meta: TurnMvpIpvG6VIq1WMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/stats/TurnMvp.vue").then(m => m.default || m)
  },
  {
    name: "region-Tables___en",
    path: "/en/:region()/tables",
    meta: Tables3ZeDsG7Cd2Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Tables.vue").then(m => m.default || m)
  },
  {
    name: "region-Tables___es",
    path: "/es/:region()/clasificacion",
    meta: Tables3ZeDsG7Cd2Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Tables.vue").then(m => m.default || m)
  },
  {
    name: "region-Tables___it",
    path: "/it/:region()/classifica",
    meta: Tables3ZeDsG7Cd2Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Tables.vue").then(m => m.default || m)
  },
  {
    name: "region-Teams___en",
    path: "/en/:region()/teams",
    meta: TeamskXCwUdMRqoMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Teams.vue").then(m => m.default || m)
  },
  {
    name: "region-Teams___es",
    path: "/es/:region()/equipos",
    meta: TeamskXCwUdMRqoMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Teams.vue").then(m => m.default || m)
  },
  {
    name: "region-Teams___it",
    path: "/it/:region()/squadre",
    meta: TeamskXCwUdMRqoMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Teams.vue").then(m => m.default || m)
  },
  {
    name: "region-teams-team-teamName___en",
    path: "/en/:region()/teams/:team()-:teamName()",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/teams/[team]-[teamName].vue").then(m => m.default || m)
  },
  {
    name: "region-teams-team-teamName___es",
    path: "/es/:region()/equipos/:team()-:teamName()",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/teams/[team]-[teamName].vue").then(m => m.default || m)
  },
  {
    name: "region-teams-team-teamName___it",
    path: "/it/:region()/squadre/:team()-:teamName()",
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/teams/[team]-[teamName].vue").then(m => m.default || m)
  },
  {
    name: "region-Tickets___en",
    path: "/en/:region()/tickets",
    meta: Tickets6F8MqVbZsXMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Tickets.vue").then(m => m.default || m)
  },
  {
    name: "region-Tickets___es",
    path: "/es/:region()/entradas",
    meta: Tickets6F8MqVbZsXMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Tickets.vue").then(m => m.default || m)
  },
  {
    name: "region-Tickets___it",
    path: "/it/:region()/biglietti",
    meta: Tickets6F8MqVbZsXMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Tickets.vue").then(m => m.default || m)
  },
  {
    name: "region-TierList___en",
    path: "/en/:region()/tier-list",
    meta: TierListO44q28siY1Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/TierList.vue").then(m => m.default || m)
  },
  {
    name: "region-TierList___es",
    path: "/es/:region()/tier-list",
    meta: TierListO44q28siY1Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/TierList.vue").then(m => m.default || m)
  },
  {
    name: "region-TierList___it",
    path: "/it/:region()/tier-list",
    meta: TierListO44q28siY1Meta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/TierList.vue").then(m => m.default || m)
  },
  {
    name: "region-Tournament___en",
    path: "/en/:region()/tournament",
    meta: TournamentY4hn8EOKtMMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Tournament.vue").then(m => m.default || m)
  },
  {
    name: "region-Tournament___es",
    path: "/es/:region()/torneo",
    meta: TournamentY4hn8EOKtMMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Tournament.vue").then(m => m.default || m)
  },
  {
    name: "region-Tournament___it",
    path: "/it/:region()/torneo",
    meta: TournamentY4hn8EOKtMMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/[region]/Tournament.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/src/app/nuxt-ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/usr/src/app/nuxt-ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/usr/src/app/nuxt-ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "players-playerId-playerName___en",
    path: "/en/players/:playerId()-:playerName()",
    meta: _91playerId_93_45_91playerName_93GpiEs1faILMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/players/[playerId]-[playerName].vue").then(m => m.default || m)
  },
  {
    name: "players-playerId-playerName___es",
    path: "/es/jugadores/:playerId()-:playerName()",
    meta: _91playerId_93_45_91playerName_93GpiEs1faILMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/players/[playerId]-[playerName].vue").then(m => m.default || m)
  },
  {
    name: "players-playerId-playerName___it",
    path: "/it/giocatori/:playerId()-:playerName()",
    meta: _91playerId_93_45_91playerName_93GpiEs1faILMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/players/[playerId]-[playerName].vue").then(m => m.default || m)
  },
  {
    name: "PlayersLegend___en",
    path: "/en/legends",
    meta: PlayersLegendzoVeq6IeMpMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/PlayersLegend.vue").then(m => m.default || m)
  },
  {
    name: "PlayersLegend___es",
    path: "/es/leyendas",
    meta: PlayersLegendzoVeq6IeMpMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/PlayersLegend.vue").then(m => m.default || m)
  },
  {
    name: "PlayersLegend___it",
    path: "/it/leggende",
    meta: PlayersLegendzoVeq6IeMpMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/PlayersLegend.vue").then(m => m.default || m)
  },
  {
    name: "StoresLanding___en",
    path: "/en/stores",
    meta: StoresLandingQGEO7EvhLOMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/StoresLanding.vue").then(m => m.default || m)
  },
  {
    name: "StoresLanding___es",
    path: "/es/tiendas",
    meta: StoresLandingQGEO7EvhLOMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/StoresLanding.vue").then(m => m.default || m)
  },
  {
    name: "StoresLanding___it",
    path: "/it/negozios",
    meta: StoresLandingQGEO7EvhLOMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/StoresLanding.vue").then(m => m.default || m)
  },
  {
    name: "teams-team-teamName___en",
    path: "/en/teams/:team()-:teamName()",
    meta: _91team_93_45_91teamName_93eRs7wovPZNMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/teams/[team]-[teamName].vue").then(m => m.default || m)
  },
  {
    name: "teams-team-teamName___es",
    path: "/es/equipos/:team()-:teamName()",
    meta: _91team_93_45_91teamName_93eRs7wovPZNMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/teams/[team]-[teamName].vue").then(m => m.default || m)
  },
  {
    name: "teams-team-teamName___it",
    path: "/it/squadre/:team()-:teamName()",
    meta: _91team_93_45_91teamName_93eRs7wovPZNMeta || {},
    component: () => import("/usr/src/app/nuxt-ui/pages/teams/[team]-[teamName].vue").then(m => m.default || m)
  }
]