<!--
// nuxt-ui/components/region-stats/cards-featured-stats/CardPlayerRankingCards.vue
-->
<script setup lang="ts">
import { useImages } from "~/nuxt-ui/Shared/composables/images";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { setAliasUrl } from "~/src/Shared/utils";
import { initDropdowns } from "flowbite";

type Props = {
  seasonId: number | string;
  isStats?: boolean; // Usage is in /stats
};

const props = defineProps<Props>();

const localePath = useLocalePath();
const route = useRoute();
const { regionParam, isRegionHome } = useRegions();
const { playerPlaceholderPath, videoBgFeaturedStatsPath, posterBgFeaturedStatsPath } = useImages();
const { isKings, webContextGenders } = useWebContext();

const { data: topCards } = await useFetch("/api/stats", {
  query: {
    ctxGenders: webContextGenders,
    season: props.seasonId,
    type: "players",
    mainCode: "CRT-G",
    secondaryCodes: "CRT-R",
    orderBy: "ranking",
    sortOrder: "ASC",
  },
  transform: input => {
    return input[0];
  },
});

const queries = computed(() => {
  return route.query ? route.query : undefined;
});

const videoBg = ref<HTMLVideoElement | null>(null);

const playVideoBg = () => {
  if (videoBg.value && "play" in videoBg.value) {
    videoBg.value.play();
  }
};

const pauseVideoBg = () => {
  if (videoBg.value && "pause" in videoBg.value) {
    videoBg.value.pause();
  }
};

onMounted(() => {
  initDropdowns();
});
</script>

<template>
  <div
    v-if="!!topCards"
    class="flex flex-col max-w-[343px] w-full min-w-full lg:max-w-none 2xl:w-auto 2xl:aspect-[325/568]"
  >
    <div class="card-player-image-container">
      <nuxt-link
        :to="
          localePath({
            name: `region-stats-Cards`,
            params: { region: regionParam },
            query: queries,
          })
        "
        @mouseover="playVideoBg"
        @mouseout="pauseVideoBg"
        :no-prefetch="!!isRegionHome"
      >
        <video
          :src="videoBgFeaturedStatsPath"
          :poster="posterBgFeaturedStatsPath"
          class="absolute top-0 left-0 right-0 bottom-0 min-h-[450px] object-cover object-top"
          ref="videoBg"
          width="100%"
          height="100%"
          loop
          muted
        ></video>
        <div class="image-container">
          <NuxtImg
            :src="
              topCards?.player?.image?.url ? setAliasUrl(topCards.player.image.url) : playerPlaceholderPath
            "
            :alt="topCards?.player?.shortName || ''"
            class="object-top object-cover min-h-[450px]"
            width="456"
            height="642"
            sizes="256px sm:472px md:325px"
          />
        </div>
        <div class="gradient-container">
          <div>
            <div class="title-stat">{{ $t("cards.stats.title.cards") }}</div>
          </div>
        </div>
      </nuxt-link>
    </div>

    <div class="mt-4 table-first-stats">
      <div class="flex items-center justify-end text-gray-400 text-[10px] uppercase pl-2 pr-1">
        <span class="w-4/6 text-left">{{ $t("cards.stats.name.text") }}</span>
        <span class="w-1/6 text-center ml-auto min-w-min mr-1">{{ $t("cards.stats.team.text") }}</span>
        <span class="w-8 flex items-center justify-center">
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="yellowCard">
              <path
                id="Rectangle 1724"
                d="M4.5 4.46257C4.5 3.66483 5.1467 3.01813 5.94444 3.01813H11.7222C12.52 3.01813 13.1667 3.66483 13.1667 4.46257V13.1292C13.1667 13.927 12.52 14.5737 11.7222 14.5737H5.94444C5.1467 14.5737 4.5 13.927 4.5 13.1292V4.46257Z"
                fill="#FFCF00"
              />
            </g>
          </svg>
        </span>
        <span class="w-8 flex items-center justify-center">
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="redCard">
              <path
                id="Rectangle 1724"
                d="M4 4.46257C4 3.66483 4.6467 3.01813 5.44444 3.01813H11.2222C12.02 3.01813 12.6667 3.66483 12.6667 4.46257V13.1292C12.6667 13.927 12.02 14.5737 11.2222 14.5737H5.44444C4.6467 14.5737 4 13.927 4 13.1292V4.46257Z"
                fill="#E62D4F"
              />
            </g>
          </svg>
        </span>
      </div>
      <div class="mt-2 flex items-center justify-end text-sm font-bold bg-gray-800 pl-2 pr-1 h-14 rounded-md">
        <component :is="isStats ? 'h2' : 'div'" class="w-4/6 text-left">
          {{ topCards?.player?.shortName }}
        </component>
        <div class="w-1/6 min-w-min mr-1 logo-team-container">
          <NuxtImg
            v-if="topCards?.team?.logo?.url"
            :src="setAliasUrl(topCards?.team?.logo?.url)"
            alt="Team"
            class="object-contain h-8 w-8 lg:w-11 lg:h-11"
            loading="lazy"
            height="44"
            width="44"
          />
        </div>
        <div class="w-8 flex items-center justify-center">
          {{ Number(topCards?.rankingData["CRT-G"].total) }}
        </div>
        <div class="w-8 flex items-center justify-center">
          {{ Number(topCards?.rankingData["CRT-R"].total) }}
        </div>
      </div>
    </div>
    <div class="text-center py-2" v-if="regionParam">
      <nuxt-link
        :to="
          localePath({
            name: `region-stats-Cards`,
            params: { region: regionParam },
            query: queries,
          })
        "
        class="see-more-link"
        :no-prefetch="!!isRegionHome"
        >{{ $t("cards.stats.seeMore.link") }}
      </nuxt-link>
    </div>
  </div>
</template>

<style scoped>
.card-player-image-container {
  @apply min-h-[450px] px-1 py-2 rounded-lg relative overflow-hidden bg-no-repeat bg-cover bg-center;
}

.image-container {
  @apply h-full absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-start items-center pt-5;
}

.gradient-container {
  @apply absolute min-w-full min-h-full top-0 right-0 left-0 bottom-0 bg-[linear-gradient(180deg,rgba(0,0,0,0.00)68.26%,rgba(0,0,0,0.80)100%)] px-1 py-2 flex flex-col justify-between;
}

.title-stat {
  @apply text-black text-center text-2xl font-bold uppercase font-['Archivo_Expanded'] leading-none;
  -webkit-text-stroke: 1px theme("colors.primary.light");
  text-stroke: 1px theme("colors.primary.light");
}

.logo-team-container {
  @apply flex flex-col justify-center items-center ml-auto;
}

.see-more-link {
  @apply text-xs text-gray-400;
}
</style>
