// nuxt-ui/Shared/composables/twitch.ts

import type { TwitchLive } from "~/src/twitch/domain/models/TwitchLive";
import type { TwitchLiveDTO } from "~/src/twitch/infrastructure/DTO/TwitchLiveDTO";
import type { TwitchInfoDTO } from "~/src/twitch/infrastructure/DTO/TwitchInfoDTO";
import type { TwitchInfo } from "~/src/twitch/domain/models/TwitchInfo";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";

interface UseTwitch {
  getTwitchLiveInfo: () => Promise<TwitchLive | void>;
  getTwitchLiveInfoFromRegion: (region: string) => Promise<TwitchLive | void>;
  getTwitchInfo: (region: string, lang: string) => Promise<TwitchInfo | undefined>;
}

export const useTwitch = (): UseTwitch => {
  return {
    getTwitchLiveInfo,
    getTwitchLiveInfoFromRegion,
    getTwitchInfo,
  };

  async function getTwitchLiveInfo(): Promise<TwitchLive | void> {
    try {
      const { region } = useCompetitionStore();

      if (!region) {
        console.error("Region is not defined.");
        return;
      }

      //todo - create world-cup at backend
      const path =
        region.slug === "world-cup" || region.slug === "kwc-nations"
          ? "/api/prd/twitch-users/espana"
          : `/api/prd/twitch-users/${region.slug}`;

      const response = await $fetch<TwitchLiveDTO>(path);

      if (response) {
        return response;
      } else {
        console.error("NOTE: Fetched Twitch live info is null or undefined");
      }
    } catch (e) {
      console.error("An error occurred while fetching Twitch live info:", e);
    }
  }

  async function getTwitchInfo(region: string, lang: string): Promise<TwitchInfo | undefined> {
    try {
      if (!region) {
        console.error("Region is not defined.");
        return;
      }

      const path = "/api/prd/twitch-info";
      const opts = { query: { region, language: lang } };

      const response = await $fetch<TwitchInfoDTO>(path, opts);

      if (response) {
        return response;
      } else {
        console.error("NOTE: Fetched Twitch info is null or undefined");
      }
    } catch (e) {
      console.error("An error occurred while fetching Twitch info:", e);
    }
  }

  async function getTwitchLiveInfoFromRegion(regionSlug: string): Promise<TwitchLive | void> {
    try {
      if (!regionSlug) {
        console.error("Region is not defined.");
        return;
      }

      //todo - create world-cup at backend
      const path =
        regionSlug === "world-cup" ? "/api/prd/twitch-users/espana" : `/api/prd/twitch-users/${regionSlug}`;

      const response = await $fetch<TwitchLiveDTO>(path);

      if (response) {
        return response;
      } else {
        console.error("NOTE: Fetched Twitch live info is null or undefined");
      }
    } catch (e) {
      console.error("An error occurred while fetching Twitch live info:", e);
    }
  }
};
