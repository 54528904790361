// src/region/datasources/regionsData.ts

import type { WebContext } from "~/src/Shared/Types";
import type { RegionDTO } from "~/src/region/infrastructure/RegionDTO";
import type { MenuDTO } from "~/src/region/infrastructure/MenuDTO";

// competitions data (menu products) / webContext
const regionsKings: RegionDTO[] = [
  {
    id: 1,
    apiId: 13,
    competitionId: [3, 6],
    name: "Spain",
    slug: "espana",
    navVisible: true,
    storeUrl: "https://store.kingsleague.pro/",
    timeZone: "Europe/Madrid",
    cmsKey: "https://cms-es.kingsleague.pro",
    social: [
      {
        name: "youtube",
        url: "https://www.youtube.com/@KingsLeagueOfficial",
      },
      {
        name: "twitch",
        url: "https://www.twitch.tv/kingsleague",
      },
      {
        name: "twitter",
        url: "https://x.com/kingsleague",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/kingsleague/",
      },
      {
        name: "tiktok",
        url: "https://www.tiktok.com/@kingsleague",
      },
      {
        name: "discord",
        url: "https://discord.gg/XMr4vsPrYM",
      },
    ],
    footerLogo: {
      src: "/img/league-logos/Footer-Kings-League-Infojobs.svg",
      alt: "Kings League InfoJobs",
      width: 312,
      height: 68,
    },
    leagueTitle: "Kings League InfoJobs",
    ticketsUrl:
      "https://www.ticketone.it/en/artist/kings-world-cup-nations-finals/kings-world-cup-nations-final-3765195/?affiliate=KOS",
  },
  {
    id: 2,
    apiId: 57,
    competitionId: [5],
    name: "Americas",
    slug: "americas",
    navVisible: true,
    storeUrl: "StoresLanding",
    timeZone: "America/Mexico_City",
    cmsKey: "https://cms-am.kingsleague.pro",
    social: [
      {
        name: "youtube",
        url: "https://www.youtube.com/@KingsLeagueAmericas",
      },
      {
        name: "twitch",
        url: "https://www.twitch.tv/kingsleagueamericas",
      },
      {
        name: "twitter",
        url: "https://x.com/kingsleague_am",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/kingsleagueamericas/",
      },
      {
        name: "tiktok",
        url: "https://www.tiktok.com/@kingsleagueamericas",
      },
    ],
    footerLogo: {
      src: "/img/league-logos/Footer-Kings-League-Americas-Santander.png",
      alt: "Kings League Santander",
      width: 312,
      height: 80,
    },
    leagueTitle: "Kings League Santander",
    ticketsUrl:
      "https://www.ticketone.it/en/artist/kings-world-cup-nations-finals/kings-world-cup-nations-final-3765195/?affiliate=KOS",
  },
  {
    id: 4,
    apiId: 0,
    competitionId: [10],
    name: "Italy",
    slug: "italia",
    navVisible: true,
    storeUrl: "Storeslanding",
    timeZone: "Europe/Rome",
    cmsKey: "",
    social: [
      {
        name: "youtube",
        url: "https://www.youtube.com/@KingsLeagueIT",
      },
      {
        name: "twitch",
        url: "https://www.twitch.tv/kingsleague_it",
      },
      {
        name: "twitter",
        url: "https://x.com/KingsLeague_IT",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/kingsleague_it/",
      },
    ],
    footerLogo: {
      src: "/img/league-logos/Footer-kings-league-italia.svg",
      alt: "Kings League Italy",
      width: 312,
      height: 66,
    },
    leagueTitle: "Kings League Italy",
    ticketsUrl:
      "https://www.ticketone.it/en/artist/kings-world-cup-nations-finals/kings-world-cup-nations-final-3765195/?affiliate=KOS",
  },
  {
    id: 6,
    apiId: 204,
    competitionId: [0],
    name: "Brazil",
    slug: "brazil",
    navVisible: true,
    storeUrl: "StoresLanding",
    timeZone: "America/Sao_Paulo",
    cmsKey: "",
    social: [
      {
        name: "youtube",
        url: "https://www.youtube.com/@KingsLeagueBrazil",
      },
      {
        name: "twitter",
        url: "https://x.com/Kings_LeagueBR",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/kingsleague_br/",
      },
    ],
    footerLogo: {
      src: "/img/league-logos/Footer-kings-league-brazil.png",
      alt: "Kings League Brazil",
      width: 312,
      height: 81,
    },
    leagueTitle: "Kings League Brazil",
    ticketsUrl:
      "https://www.ticketone.it/en/artist/kings-world-cup-nations-finals/kings-world-cup-nations-final-3765195/?affiliate=KOS",
  },
  {
    id: 5,
    apiId: 204,
    competitionId: [18],
    name: "KWC Nations",
    slug: "kwc-nations",
    navVisible: true,
    storeUrl: "StoresLanding",
    timeZone: "Europe/Rome",
    cmsKey: "https://cms-es.kingsleague.pro",
    social: [
      {
        name: "youtube",
        url: "https://www.youtube.com/@KingsLeagueOfficial",
      },
      {
        name: "twitter",
        url: "https://twitter.com/_KingsWorld",
      },
      {
        name: "twitch",
        url: "https://www.twitch.tv/kingsworld",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/kingsworld/",
      },
      {
        name: "tiktok",
        url: "https://www.tiktok.com/@kingsworld",
      },
    ],
    footerLogo: {
      src: "/img/league-logos/Footer-kwc-nations.png",
      alt: "Kings World Cup Nations",
      width: 220,
      height: 98.8,
    },
    leagueTitle: "Kings World Cup Nations",
    ticketsUrl:
      "https://www.ticketone.it/en/artist/kings-world-cup-nations-finals/kings-world-cup-nations-final-3765195/?affiliate=KOS",
  },
  {
    id: 3,
    apiId: 204,
    competitionId: [8, 9],
    name: "KWC Clubs",
    slug: "world-cup",
    navVisible: true,
    storeUrl: "StoresLanding",
    timeZone: "America/Mexico_City",
    cmsKey: "https://cms-es.kingsleague.pro",
    social: [
      {
        name: "youtube",
        url: "https://www.youtube.com/@KingsLeagueOfficial",
      },
      {
        name: "twitter",
        url: "https://twitter.com/_KingsWorld",
      },
      {
        name: "twitch",
        url: "https://www.twitch.tv/kingsworld",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/kingsworld/",
      },
      {
        name: "tiktok",
        url: "https://www.tiktok.com/@kingsworld",
      },
    ],
    footerLogo: {
      src: "/img/league-logos/Footer-Kings-World-Cup.svg",
      alt: "Kings World Cup",
      width: 220,
      height: 86,
    },
    leagueTitle: "Kings World Cup Clubs",
    ticketsUrl:
      "https://www.ticketone.it/en/artist/kings-world-cup-nations-finals/kings-world-cup-nations-final-3765195/?affiliate=KOS",
  },
  {
    id: 7,
    apiId: 204,
    competitionId: [0],
    name: "King of Kings",
    slug: "king-of-kings",
    navVisible: false,
    storeUrl: "StoresLanding",
    timeZone: "Europe/Rome",
    cmsKey: "https://cms-es.kingsleague.pro",
    social: [],
    footerLogo: {
      src: "/img/league-logos/Footer-king-of-kings.png",
      alt: "King of Kings",
      width: 160,
      height: 81.03,
    },
    leagueTitle: "King of Kings",
    ticketsUrl: "",
  },
];

const regionsQueens: RegionDTO[] = [
  {
    id: 1,
    apiId: 13,
    competitionId: [4, 6],
    name: "Spain",
    slug: "espana",
    navVisible: true,
    storeUrl: "https://store.queensleague.pro/",
    timeZone: "Europe/Madrid",
    cmsKey: "https://cms-es.kingsleague.pro",
    social: [
      {
        name: "youtube",
        url: "https://www.youtube.com/@KingsLeagueOfficial",
      },
      {
        name: "twitch",
        url: "https://www.twitch.tv/kingsleague",
      },
      {
        name: "twitter",
        url: "https://x.com/QueensLeague",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/queensleague/?hl=es",
      },
      {
        name: "tiktok",
        url: "https://www.tiktok.com/@queensleague",
      },
    ],
    footerLogo: {
      src: "/img/league-logos/Footer-Queens-League-Oysho.svg",
      alt: "Queens League Oysho",
      width: 312,
      height: 68,
    },
    leagueTitle: "Queens League Oysho",
    ticketsUrl: "region-Tickets",
  },
  {
    id: 2,
    apiId: 57,
    competitionId: [12],
    name: "Americas",
    slug: "americas",
    navVisible: true,
    storeUrl: "",
    timeZone: "America/Mexico_City",
    cmsKey: "https://cms-am.kingsleague.pro",
    social: [
      {
        name: "youtube",
        url: "https://www.youtube.com/@KingsLeagueAmericas",
      },
      {
        name: "twitch",
        url: "https://www.twitch.tv/kingsleagueamericas",
      },
      {
        name: "twitter",
        url: "https://x.com/queensleagueam_",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/queensleagueam/",
      },
      {
        name: "tiktok",
        url: "https://www.tiktok.com/@queensleagueamericas",
      },
      {
        name: "facebook",
        url: "https://www.facebook.com/queensleagueoyshoamericas",
      },
    ],
    footerLogo: {
      src: "/img/league-logos/Footer-Queens-League-Oysho-Americas.png",
      alt: "Queens League Oysho Americas",
      width: 312,
      height: 74.2,
    },
    leagueTitle: "Queens League Oysho Americas",
    ticketsUrl: "",
  },
];

// navigation menus / webContext
const navigationMenuKings: MenuDTO[] = [
  {
    region: "espana",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "players",
        link: "#",
        children: [
          {
            name: "playersDraft",
            link: "region-PlayersDraft",
          },
          {
            name: "12-13",
            link: "region-PlayersVariable",
          },
          {
            name: "legends",
            link: "PlayersLegend",
          },
        ],
      },
      {
        name: "competition",
        link: "#",
        children: [
          {
            name: "matches",
            link: "region-Matches",
          },
          {
            name: "tables",
            link: "region-Tables",
          },
          {
            name: "stats",
            link: "region-Stats",
          },
        ],
      },
      {
        name: "draft",
        link: "#",
        children: [
          {
            name: "tryouts",
            link: "region-Draft",
          },
          {
            name: "reserves",
            link: "region-DraftReserves",
          },
        ],
      },
      {
        name: "squadSimulator",
        link: "region-SquadSimulator",
        lang: ["it"],
      },
      { name: "mercato", link: "region-Mercato", lang: ["en", "es"] },
      {
        name: "fantasy",
        link: "https://kingsleague.hro.gg/",
      },
      {
        name: "store",
        link: "#",
        children: [
          {
            name: "storeSpain",
            link: "https://store.kingsleague.pro",
          },
          {
            name: "storeAmericas",
            link: "https://storeamericas.kingsleague.pro",
          },
        ],
      },
    ],
  },
  {
    region: "americas",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "players",
        link: "#",
        children: [
          {
            name: "playersDraft",
            link: "region-PlayersDraft",
          },
          {
            name: "12-13",
            link: "region-PlayersVariable",
          },
          {
            name: "legends",
            link: "PlayersLegend",
          },
        ],
      },
      {
        name: "competition",
        link: "#",
        children: [
          {
            name: "matches",
            link: "region-Matches",
          },
          {
            name: "tables",
            link: "region-Tables",
          },
          {
            name: "stats",
            link: "region-Stats",
          },
        ],
      },
      {
        name: "draft",
        link: "#",
        children: [
          {
            name: "tryouts",
            link: "region-Draft",
          },
          {
            name: "reserves",
            link: "region-DraftReserves",
          },
        ],
      },
      {
        name: "fantasy",
        link: "https://klfantasyamericas.com/",
      },
      {
        name: "store",
        link: "#",
        children: [
          {
            name: "storeSpain",
            link: "https://store.kingsleague.pro",
          },
          {
            name: "storeAmericas",
            link: "https://storeamericas.kingsleague.pro",
          },
        ],
      },
    ],
  },
  {
    region: "italia",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "draft",
        link: "#",
        children: [
          {
            name: "tryouts",
            link: "region-Draft",
          },
          {
            name: "reserves",
            link: "region-DraftReserves",
          },
        ],
      },
      {
        name: "castersIt",
        link: "https://forms.kingsleague.pro/it/kl_ita_tryouts_30-0",
        lang: ["it"],
      },
    ],
  },
  {
    region: "brazil",
    children: [
      {
        name: "draft",
        link: "region",
      },
    ],
  },
  {
    region: "world-cup",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "players",
        link: "#",
        children: [
          {
            name: "playersDraft",
            link: "region-PlayersDraft",
          },
          {
            name: "12-13",
            link: "region-PlayersVariable",
          },
          {
            name: "legends",
            link: "PlayersLegend",
          },
        ],
      },
      {
        name: "competition",
        link: "#",
        children: [
          {
            name: "matches",
            link: "region-Matches",
          },
          {
            name: "tables",
            link: "region-Tables",
          },
          {
            name: "stats",
            link: "region-Stats",
          },
        ],
      },
      {
        name: "tournament",
        link: "region-Tournament",
      },
    ],
  },
  {
    region: "kwc-nations",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "players",
        link: "region-PlayersDraft",
      },
      {
        name: "matches",
        link: "region-Matches",
      },
      {
        name: "tables",
        link: "region-Tables",
      },
      {
        name: "stats",
        link: "region-Stats",
      },
      {
        name: "tournament",
        link: "region-Tournament",
      },
      {
        name: "tierList",
        link: "region-TierList",
      },
    ],
  },
];

const navigationMenuQueens: MenuDTO[] = [
  {
    region: "espana",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "players",
        link: "#",
        children: [
          {
            name: "playersDraft",
            link: "region-PlayersDraft",
          },
          {
            name: "12-13",
            link: "region-PlayersVariable",
          },
        ],
      },
      {
        name: "competition",
        link: "#",
        children: [
          {
            name: "matches",
            link: "region-Matches",
          },
          {
            name: "tables",
            link: "region-Tables",
          },
          {
            name: "stats",
            link: "region-Stats",
          },
        ],
      },
      {
        name: "draft",
        link: "#",
        children: [
          {
            name: "tryouts",
            link: "region-Draft",
          },
          {
            name: "reserves",
            link: "region-DraftReserves",
          },
        ],
      },
      {
        name: "squadSimulator",
        link: "region-SquadSimulator",
        lang: ["it"],
      },
      { name: "mercato", link: "region-Mercato", lang: ["en", "es"] },
      {
        name: "store",
        link: "https://store.queensleague.pro/",
      },
    ],
  },
  {
    region: "americas",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "players",
        link: "#",
        children: [
          {
            name: "playersDraft",
            link: "region-PlayersDraft",
          },
          {
            name: "12-13",
            link: "region-PlayersVariable",
          },
        ],
      },
      {
        name: "competition",
        link: "#",
        children: [
          {
            name: "matches",
            link: "region-Matches",
          },
          {
            name: "tables",
            link: "region-Tables",
          },
          {
            name: "stats",
            link: "region-Stats",
          },
        ],
      },
      {
        name: "draft",
        link: "#",
        children: [
          {
            name: "tryouts",
            link: "region-Draft",
          },
          {
            name: "reserves",
            link: "region-DraftReserves",
          },
        ],
      },
    ],
  },
];

// footer menus / webContext
const footerMenuKings: MenuDTO[] = [
  {
    region: "espana",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "playersDraft",
        link: "region-PlayersDraft",
      },
      {
        name: "12-13",
        link: "region-PlayersVariable",
      },
      {
        name: "legends",
        link: "PlayersLegend",
      },
      {
        name: "matches",
        link: "region-Matches",
      },
      {
        name: "tables",
        link: "region-Tables",
      },
      {
        name: "stats",
        link: "region-Stats",
      },
      {
        name: "draft",
        link: "region-Draft",
      },
      {
        name: "squadSimulator",
        link: "region-SquadSimulator",
      },
      {
        name: "news",
        link: "region-news",
      },
      { name: "mercato", link: "region-Mercato", lang: ["en", "es"] },
      {
        name: "regulation",
        link: "region-Regulation",
      },
      {
        name: "tickets",
        link: "region-Tickets",
      },
      {
        name: "contact",
        link: "region-ContactUs",
      },
      {
        name: "careers",
        link: "https://kosmos.teamtailor.com/",
      },
    ],
  },
  {
    region: "americas",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "playersDraft",
        link: "region-PlayersDraft",
      },
      {
        name: "12-13",
        link: "region-PlayersVariable",
      },
      {
        name: "legends",
        link: "PlayersLegend",
      },
      {
        name: "matches",
        link: "region-Matches",
      },
      {
        name: "tables",
        link: "region-Tables",
      },
      {
        name: "stats",
        link: "region-Stats",
      },
      {
        name: "draft",
        link: "region-Draft",
      },
      {
        name: "news",
        link: "region-news",
      },
      {
        name: "regulation",
        link: "region-Regulation",
      },
      {
        name: "contact",
        link: "region-ContactUs",
      },
      {
        name: "careers",
        link: "https://kosmos.teamtailor.com/",
      },
    ],
  },
  {
    region: "italia",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "draft",
        link: "region-Draft",
      },
      {
        name: "careers",
        link: "https://kosmos.teamtailor.com/",
      },
    ],
  },
  {
    region: "brazil",
    children: [
      {
        name: "draft",
        link: "region",
      },
      {
        name: "careers",
        link: "https://kosmos.teamtailor.com/",
      },
    ],
  },
  {
    region: "world-cup",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "playersDraft",
        link: "region-PlayersDraft",
      },
      {
        name: "12-13",
        link: "region-PlayersVariable",
      },
      {
        name: "legends",
        link: "PlayersLegend",
      },
      {
        name: "matches",
        link: "region-Matches",
      },
      {
        name: "tables",
        link: "region-Tables",
      },
      {
        name: "stats",
        link: "region-Stats",
      },
      {
        name: "tournament",
        link: "region-Tournament",
      },
      {
        name: "draft",
        link: "region-JoinTheDraft",
      },
      {
        name: "tierList",
        link: "region-TierList",
      },
      {
        name: "news",
        link: "region-news",
      },
      {
        name: "regulation",
        link: "region-Regulation",
      },
      {
        name: "contact",
        link: "region-ContactUs",
      },
      {
        name: "careers",
        link: "https://kosmos.teamtailor.com/",
      },
    ],
  },
  {
    region: "kwc-nations",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "players",
        link: "region-PlayersDraft",
      },
      {
        name: "matches",
        link: "region-Matches",
      },
      {
        name: "tables",
        link: "region-Tables",
      },
      {
        name: "stats",
        link: "region-Stats",
      },
      {
        name: "tournament",
        link: "region-Tournament",
      },
      {
        name: "tierList",
        link: "region-TierList",
      },
      {
        name: "news",
        link: "region-news",
      },
      {
        name: "regulation",
        link: "region-Regulation",
      },
      {
        name: "contact",
        link: "region-ContactUs",
      },
      {
        name: "careers",
        link: "https://kosmos.teamtailor.com/",
      },
    ],
  },
  {
    region: "king-of-kings",
    children: [
      {
        name: "qualifiersSpain",
        link: "region",
      },
      {
        name: "qualifiersAmericas",
        link: "region-KokQualifiersAmericas",
      },
      {
        name: "careers",
        link: "https://kosmos.teamtailor.com/",
      },
    ],
  },
];

const footerMenuQueens: MenuDTO[] = [
  {
    region: "espana",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "playersDraft",
        link: "region-PlayersDraft",
      },
      {
        name: "12-13",
        link: "region-PlayersVariable",
      },
      {
        name: "matches",
        link: "region-Matches",
      },
      {
        name: "tables",
        link: "region-Tables",
      },
      {
        name: "stats",
        link: "region-Stats",
      },
      {
        name: "draft",
        link: "region-Draft",
      },
      {
        name: "squadSimulator",
        link: "region-SquadSimulator",
      },
      {
        name: "news",
        link: "region-news",
      },
      { name: "mercato", link: "region-Mercato", lang: ["es", "en"] },
      {
        name: "regulation",
        link: "region-Regulation",
      },
      {
        name: "tickets",
        link: "region-Tickets",
      },
      {
        name: "contact",
        link: "region-ContactUs",
      },
      {
        name: "careers",
        link: "https://kosmos.teamtailor.com/",
      },
    ],
  },
  {
    region: "americas",
    children: [
      {
        name: "teams",
        link: "region-Teams",
      },
      {
        name: "playersDraft",
        link: "region-PlayersDraft",
      },
      {
        name: "12-13",
        link: "region-PlayersVariable",
      },
      {
        name: "matches",
        link: "region-Matches",
      },
      {
        name: "tables",
        link: "region-Tables",
      },
      {
        name: "stats",
        link: "region-Stats",
      },
      {
        name: "draft",
        link: "region-Draft",
      },
      {
        name: "news",
        link: "region-news",
      },
      {
        name: "regulation",
        link: "region-Regulation",
      },
      {
        name: "contact",
        link: "region-ContactUs",
      },
      {
        name: "careers",
        link: "https://kosmos.teamtailor.com/",
      },
    ],
  },
];

export const getWebContextRegionsData = (webContext: WebContext) => {
  if (webContext === "queens") {
    return regionsQueens;
  } else {
    return regionsKings;
  }
};

export const getWebContextNavigationMenu = (webContext: WebContext) => {
  if (webContext === "queens") {
    return navigationMenuQueens;
  } else {
    return navigationMenuKings;
  }
};

export const getWebContextFooterMenu = (webContext: WebContext) => {
  if (webContext === "queens") {
    return footerMenuQueens;
  } else {
    return footerMenuKings;
  }
};

export const getCompetitionIdsToMapInfo = (webContext: WebContext): number[] => {
  const regions = getWebContextRegionsData(webContext);
  const competitionIds = regions.map(r => r.competitionId);
  return [...new Set(competitionIds.flat())].filter(n => n !== 0);
};
