// src/post/datasources/cmsCategoriesData.ts

import type { CmsCategoryIds } from "~/src/post/domain/models/Post";
import type { WebContext } from "~/src/Shared/Types";

const cmsCategoriesKings: CmsCategoryIds = {
  0: {},
  1: {
    es: { general: 16, featured: 21, external: 8 },
    en: { general: 24, featured: 25, external: 26 },
    it: { general: 197, featured: 198, external: 199 },
  },
  2: { es: { general: 16, featured: 21, external: 8 } },
  3: {
    es: { general: 142, featured: 143, external: 8 },
    en: { general: 144, featured: 145, external: 8 },
    it: { general: 203, featured: 204, external: 8 },
  },
  5: {
    es: { general: 208, featured: 209, external: 8 },
    en: { general: 210, featured: 211, external: 26 },
    it: { general: 212, featured: 213, external: 26 },
  },
};

const cmsCategoriesQueens: CmsCategoryIds = {
  0: {},
  1: {
    es: { general: 17, featured: 22, external: 18 },
    en: { general: 28, featured: 29, external: 30 },
    it: { general: 200, featured: 201, external: 202 },
  },
  2: { es: { general: 125, featured: 131, external: 132 } },
};

export const getWebContextCmsCategories = (webContext: WebContext): CmsCategoryIds => {
  if (webContext === "queens") {
    return cmsCategoriesQueens;
  } else {
    return cmsCategoriesKings;
  }
};
