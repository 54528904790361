// nuxt-ui/middleware/regionGuard.global.ts
import type { Region } from "~/src/region/domain/models/Region";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { getWebContextRegionsData } from "~/src/region/datasources/regionsData";

export default defineNuxtRouteMiddleware(to => {
  const { webContext } = useWebContext();
  const regions: Region[] = getWebContextRegionsData(webContext);

  if (regions?.length) {
    const availableRegions = regions.map(r => {
      return r.slug;
    });

    const toParams = to.params;
    const regionParam = toParams.region;
    if (!regionParam) return;

    if (typeof regionParam === "string" && !availableRegions.includes(regionParam)) {
      throw createError({ statusCode: 404 });
    }
  } else {
    throw createError({ statusCode: 500, statusMessage: `Regions unavailable: ${to.fullPath}` });
  }
});
