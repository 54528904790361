// src/match/domain/services/matchService.ts

import type { ParameterCode } from "~/src/stats/domain/models/Parameter";
import type { Match, MatchStatusSimplified } from "~/src/match/domain/models/Match";
import type { Event } from "~/src/match/domain/models/Event";
import type { MatchDTO } from "~/src/match/infrastructure/DTO/MatchDTO";
import matchUiService from "~/src/match/infrastructure/ui-services/matchUiService";
import seasonService from "~/src/season-cluster/domain/services/seasonService";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

interface MatchService {
  matchHasPage: (matchId: number | string) => boolean;
  matchIsLive: (match: Match) => boolean;
  matchStartsSoon: (match: Match) => boolean;
  trackLiveMatch: (match: Match) => boolean;
  findGoalEvent: (parameterAppString: string, avoidOwnGoal?: boolean) => ParameterCode;
  findGoalEventImage: (parameterAppString: string) => string;
  findMissedPenalty: (parameterAppString: string) => ParameterCode | string;
  findMissedPenaltyImage: (parameterAppString: string) => string;
  findMissedShootoutImage: (parameterAppString: string) => string;
  canShowMatchStats: (matchStatus: MatchStatusSimplified) => boolean;
  isAwayTeamEvent: (event: Event, homeTeamId: number | undefined, awayTeamId: number | undefined) => boolean;
  isOwnGoal: (parameterAppString: string) => boolean;
  orderMatchesByDate: (matches: Match[]) => Match[];
  normalizeMatchPageMatchDto: (match: MatchDTO) => Match;
}

export const $MATCH_REFRESH_INTERVAL = 8000;
export const $MATCH_REFRESH_INTERVAL_SLIDE = 30000;
export const $MATCH_TIMELINE_EVENT_PARAMS: ParameterCode[] = [
  "CARD-2X-ACT",
  "CARD-JOK-ACT",
  "CARD-SANC-ACT",
  "CARD-RIG-ACT",
  "CARD-SHO-ACT",
  "CARD-STAR-ACT",
  "CRT-G",
  "CRT-R",
  "GOL-2X",
  "GOL-ALL",
  "GOL-R",
  "GOL-SHO",
  "RIG-F",
  "SHO-F",
  "VAR-REF",
  "VAR-TEAM",
  "GOL-R-PRES",
  "SHO-F-PRES",
  "GOL-SHO-PRES",
  "RIG-F-PRES",
];

export const $MATCH_DICE_PARAMS: ParameterCode[] = [
  "DICE-1-ACT",
  "DICE-1GK-ACT",
  "DICE-2-ACT",
  "DICE-3-ACT",
  "DICE-4-ACT",
  "DICE-5-ACT",
  "DICE-6-ACT",
  "DICE-7-ACT",
];

const $MATCH_STARTS_SOON_CHECK_MS = 11 * 60 * 1000;

const matchService = (): MatchService => ({
  matchHasPage,
  matchIsLive,
  matchStartsSoon,
  trackLiveMatch,
  findGoalEvent,
  findGoalEventImage,
  findMissedPenalty,
  findMissedPenaltyImage,
  findMissedShootoutImage,
  canShowMatchStats,
  isAwayTeamEvent,
  isOwnGoal,
  orderMatchesByDate,
  normalizeMatchPageMatchDto,
});

export default matchService;

/**
 * Determines if a match has a page.
 *
 * @param {number | string} matchId - The ID of the match.
 *
 * @return {boolean} True if the match has a niew ID, false otherwise.
 */
function matchHasPage(matchId: number | string): boolean {
  return !!matchId && Number(matchId) > 550;
}

/**
 * Determines if the given match is live.
 *
 * @param {Match} match - The match object to be checked.
 * @returns {boolean} Returns true if the match is live, otherwise false.
 */
function matchIsLive(match: Match): boolean {
  const { transformStatus } = matchUiService();
  const status = transformStatus(match.status);
  return status === "live";
}

/**
 * Check if a given match starts soon.
 *
 * @param {Match} match - The match object containing the date of the match.
 * @return {boolean} - True if the match starts within the next 5 minutes, false otherwise.
 */
function matchStartsSoon(match: Match): boolean {
  const matchDate = new Date(match.date);
  if (matchDate.toLocaleString() === "Invalid Date") {
    console.error("invalid match date", match.date);
    return false;
  }
  const matchDateTime = matchDate.getTime();
  const nowTime = new Date().getTime();
  const matchStartDelayMs = 1000 * 60 * 5; // 5 minutes
  const matchIsFuture = matchDateTime + matchStartDelayMs > nowTime;
  return matchIsFuture && matchDateTime - nowTime < $MATCH_STARTS_SOON_CHECK_MS;
}

/**
 * Determines if a match should be tracked.
 *
 * @param {Match} match - The match object to be checked.
 * @return {boolean} - Returns true if the match is being tracked, false otherwise.
 */
function trackLiveMatch(match: Match): boolean {
  return matchStartsSoon(match) || matchIsLive(match);
}

/**
 * Finds the correct goal event based on the given event codes.
 *
 * @param {string} parameterAppString - The application string to search for goal events.
 * @param avoidOwnGoal - Should avoid own goals.
 * @return {ParameterCode} - The goal event code found in the application string.
 */
function findGoalEvent(parameterAppString: string, avoidOwnGoal?: boolean): ParameterCode {
  const parameters = parameterAppString.split(",");
  if (parameters.some(e => e === "CARD-PRES-ACT") || parameters.some(e => e === "GOL-R-PRES")) {
    return "CARD-PRES-ACT";
  } else if (parameters.some(e => e === "CARD-RIG-ACT")) {
    return "CARD-RIG-ACT";
  } else if (parameters.some(e => e === "CARD-SHO-ACT")) {
    return "CARD-SHO-ACT";
  } else if (parameters.some(e => e === "GOL-SHO")) {
    return "GOL-SHO";
  } else if (parameters.some(e => e === "GOL-R")) {
    return "GOL-R";
  } else if (!avoidOwnGoal && isOwnGoal(parameterAppString)) {
    return "GOL-A";
  } else if (parameters.some(e => e === "GOL-2X")) {
    return "GOL-2X";
  } else if (parameters.some(e => e === "GOL-D")) {
    return "GOL-D";
  } else {
    return "GOL-ALL";
  }
}

/**
 * Finds the event image for a given app string parameter.
 *
 * @param {string} parameterAppString - The app string used to determine the event image.
 * @return {string} - The event image associated with the app string parameter.
 */
function findGoalEventImage(parameterAppString: string): string {
  const parameters = parameterAppString.split(",");
  if (parameters.some(e => e === "CARD-PRES-ACT") || parameters.some(e => e === "GOL-R-PRES")) {
    const { webContext } = useWebContext();
    return webContext === "queens" ? "CARD-PRES-ACT-QL" : "CARD-PRES-ACT-KL";
  } else if (parameters.some(e => e === "CARD-RIG-ACT")) {
    return "CARD-RIG-ACT";
  } else if (parameters.some(e => e === "CARD-SHO-ACT")) {
    return "CARD-SHO-ACT";
  } else if (parameters.some(e => e === "GOL-SHO")) {
    return "GOL-SHO";
  } else if (parameters.some(e => e === "GOL-R")) {
    return "GOL-R";
  } else if (parameters.some(e => e === "GOL-2X")) {
    return "GOL-2X";
  } else {
    return "GOL-ALL";
  }
}

/**
 * Finds the missed penalty based on the given parameter string.
 *
 * @param {string} parameterAppString - The parameter string to check.
 * @returns {ParameterCode} - The missed penalty code.
 */
function findMissedPenalty(parameterAppString: string): ParameterCode | string {
  const parameters = parameterAppString.split(",");
  if (parameters.some(e => e === "CARD-PRES-ACT-F") || parameters.some(e => e === "RIG-F-PRES")) {
    const { webContext } = useWebContext();
    return webContext === "queens" ? "CARD-PRES-ACT-QL-F" : "CARD-PRES-ACT-KL-F";
  } else {
    return "RIG-F";
  }
}

/**
 * Finds the missed penalty image based on the provided app string.
 *
 * @param {string} parameterAppString - The app string to check for penalty image.
 * @returns {string} - The missed penalty image.
 */
function findMissedPenaltyImage(parameterAppString: string): string {
  const parameters = parameterAppString.split(",");
  if (parameters.some(e => e === "CARD-PRES-ACT-F") || parameters.some(e => e === "RIG-PRES-F")) {
    const { webContext } = useWebContext();
    return webContext === "queens" ? "CARD-PRES-ACT-QL-F" : "CARD-PRES-ACT-KL-F";
  } else {
    return "RIG-F";
  }
}

/**
 * Finds the missed shootout image based on the provided parameter.
 *
 * @param {string} parameterAppString - The parameter to search for missed shootout image.
 * @returns {string} - The missed shootout image.
 */
function findMissedShootoutImage(parameterAppString: string): string {
  return "SHO-F";
}

/**
 * Determines whether the match statistics can be shown based on the match status.
 *
 * @param {string} matchStatus - The simplified match status.
 * @return {boolean} - True if the match status is "live" or "ended", false otherwise.
 */
function canShowMatchStats(matchStatus: MatchStatusSimplified): boolean {
  return matchStatus === "live" || matchStatus === "ended";
}

/**
 * Checks if a given string represents an own goal event.
 *
 * @param {string} parameterAppString - The string representing the events.
 * @return {boolean} - True if the string contains an own goal event, false otherwise.
 */
function isOwnGoal(parameterAppString: string): boolean {
  const events = parameterAppString.split(",");
  return events.some(e => e === "GOL-A");
}

/**
 * Checks whether the given event belongs to the away team.
 *
 * @param {Event} event - The event object to be checked.
 * @param {number | undefined} homeTeamId - The ID of the home team. If undefined, it will be ignored.
 * @param {number | undefined} awayTeamId - The ID of the away team. If undefined, it will be ignored.
 * @returns {boolean} - Returns true if the event belongs to the away team, otherwise returns false.
 */
function isAwayTeamEvent(
  event: Event,
  homeTeamId: number | undefined,
  awayTeamId: number | undefined
): boolean {
  const { parameterAppString, teamId } = event;
  return (
    (!isOwnGoal(parameterAppString) && teamId === awayTeamId) ||
    (isOwnGoal(parameterAppString) && teamId === homeTeamId)
  );
}

/**
 * Orders an array of Match objects by date.
 *
 * @param {Match[]} matches - The array of Match objects to be ordered.
 * @return {Match[]} - The ordered array of Match objects.
 */
function orderMatchesByDate(matches: Match[]): Match[] {
  if (!matches?.length) return [];
  try {
    const _matches: Match[] = JSON.parse(JSON.stringify(matches));

    return _matches.sort((a, b) => {
      return a.date && b.date && a.date > b.date ? 1 : a.date && b.date && a.date < b.date ? -1 : 0;
    });
  } catch (e) {
    console.error("Error ordering matches by date.", e);
    return matches;
  }
}

function normalizeMatchPageMatchDto(match: MatchDTO): Match {
  const { normalizeSeason } = seasonService();
  return {
    id: match.id,
    date: match.date,
    seasonId: match.seasonId,
    season: normalizeSeason(match.season),
    phaseId: match.phaseId,
    groupId: match.groupId,
    turnId: match.turnId,
    status: match.status || null,
    currentMinute: match.currentMinute ? match.currentMinute : undefined,
    participants: {
      homeTeamId: match.participants.homeTeamId,
      homeTeam: match.participants.homeTeam,
      awayTeamId: match.participants.awayTeamId,
      awayTeam: match.participants.awayTeam,
    },
    scores: match.scores,
    durations: match.durations,
    halfs: match.halfs,
    groupName: match.groupName,
    turnName: match.turnName,
    stadiumId: match.stadiumId,
    stadium: match.stadium,
    metaInformation: match.metaInformation,
    events: match.events ? match.events : undefined,
    stats: match.stats ? match.stats : undefined,
  };
}
