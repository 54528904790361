<!--
// nuxt-ui/components/pages/region-matches/MatchTurns.vue
-->
<script setup lang="ts">
import type { Match, MatchCenterData } from "~/src/match/domain/models/Match";
import type { DateTimeFormatOptions } from "@intlify/core-base";
import type { Team } from "~/src/team/domain/models/Team";
import BaseBackdropLoading from "~/nuxt-ui/components/base/BaseBackdropLoading.vue";
import TableRowMatchMatches from "~/nuxt-ui/components/pages/region-matches/TableRowMatchMatches.vue";
import KokTableRowMatchMatches from "~/nuxt-ui/components/pages/region-matches/KokTableRowMatchMatches.vue";
import HeaderTableMatches from "~/nuxt-ui/components/pages/region-matches/HeaderTableMatches.vue";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { isFutureDate } from "~/src/Shared/utils";

type Props = {
  groupTurns: MatchCenterData[];
  teams: Record<number, Team>;
  loading: boolean;
  timeZone?: string;
  regionParamProp?: string;
  kokQualifiers?: boolean;
  seasonId?: number;
};

const props = withDefaults(defineProps<Props>(), {
  loading: false,
});

const { locale } = useI18n();
const { regionTimeZone } = useRegions();

const dateOptions: DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  year: "2-digit",
  timeZone: props.timeZone ? props.timeZone : regionTimeZone,
};

const showGroup = (matches: Match[]): boolean => {
  const groupArr = matches.map(m => m.groupId);
  return [...new Set(groupArr)].length > 1;
};

const showTickets = (matches: Match[]): boolean => {
  const ticketsExistInSomeMatch = !!matches.find(m => m.metaInformation && m.metaInformation.tickets_url);
  const lastMatchDateIsFuture = !!isFutureDate(matches[matches.length - 1].date);
  return ticketsExistInSomeMatch && lastMatchDateIsFuture;
};

const setTurnDate = turn => {
  const turnStart = new Date(turn.startDate).toLocaleDateString(locale.value, dateOptions);
  const turnEnd = new Date(turn.finishDate).toLocaleDateString(locale.value, dateOptions);
  if (turnStart === turnEnd) {
    return new Date(turn.startDate).toLocaleDateString(locale.value, dateOptions);
  } else {
    const dayStart = turn.startDate
      ? new Date(turn.startDate).toLocaleDateString(locale.value, {
          day: "numeric",
          timeZone: props.timeZone ? props.timeZone : regionTimeZone,
        })
      : undefined;
    const dayStartMonth = turn.startDate
      ? new Date(turn.startDate).toLocaleDateString(locale.value, {
          day: "numeric",
          month: "short",
          timeZone: props.timeZone ? props.timeZone : regionTimeZone,
        })
      : undefined;
    const dayEnd = turn.finishDate
      ? new Date(turn.finishDate).toLocaleDateString(locale.value, dateOptions)
      : undefined;

    const monthStart = turn.startDate ? new Date(turn.startDate).getMonth() : undefined;
    const monthEnd = turn.finishDate ? new Date(turn.finishDate).getMonth() : undefined;

    if (monthStart !== monthEnd) {
      return `${dayStartMonth} - ${dayEnd}`;
    } else {
      return `${dayStart} - ${dayEnd}`;
    }
  }
};
</script>

<template>
  <div class="kql-container-turns">
    <base-backdrop-loading :active="loading"></base-backdrop-loading>

    <div
      v-for="(turn, index) in groupTurns"
      :key="index"
      class="bg-gray-900 lg:px-6 px-4 py-4 rounded-lg mb-2"
    >
      <div class="flex gap-4 items-center">
        <h2 class="px-2 py-0.5 rounded border-primary border w-fit text-primary mb-6 lg:text-sm text-[10px]">
          {{ turn.turnName }}
        </h2>
        <p class="text-gray-300 text-xs mb-6 lg:hidden" v-if="turn.startDate">
          {{ setTurnDate(turn) }}
        </p>
      </div>

      <header-table-matches
        :show-group="showGroup(turn.matches)"
        :show-tickets="showTickets(turn.matches)"
      ></header-table-matches>

      <div v-if="kokQualifiers" class="kql-container-match">
        <kok-table-row-match-matches
          v-for="match in turn.matches"
          :key="match.id"
          :match="match"
          :away-team="teams[match.participants.awayTeamId]"
          :home-team="teams[match.participants.homeTeamId]"
          :show-group="showGroup(turn.matches)"
          :time-zone="timeZone"
          :region-param="regionParamProp"
          :season-id="seasonId"
        ></kok-table-row-match-matches>
      </div>
      <div v-else class="kql-container-match">
        <table-row-match-matches
          v-for="match in turn.matches"
          :key="match.id"
          :match="match"
          :away-team="teams[match.participants.awayTeamId]"
          :home-team="teams[match.participants.homeTeamId]"
          :show-group="showGroup(turn.matches)"
          :show-tickets="showTickets(turn.matches)"
        ></table-row-match-matches>
      </div>
    </div>
  </div>
</template>

<style scoped>
.kql-container-turns {
  @apply relative;
}
</style>
