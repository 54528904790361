// nuxt-ui/Shared/composables/regions.ts

import type { Region } from "~/src/region/domain/models/Region";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { getWebContextRegionsData } from "~/src/region/datasources/regionsData";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

interface UseRegions {
  regionParam: string | undefined;
  isRegionHome: boolean;
  isAmericas: boolean;
  isSpain: boolean;
  isItaly: boolean;
  isBrazil: boolean;
  isWorldCup: boolean;
  isNations: boolean;
  isKokNations: boolean;
  regionTimeZone: string;
}

export function useRegions(): UseRegions {
  const route = useRoute();
  const competitionStore = useCompetitionStore();
  const { webContext } = useWebContext();
  const _regions: Region[] = getWebContextRegionsData(webContext);
  const regionParam = route.params.region;
  const currentRegions = _regions.find(region => region.slug === regionParam);

  const hasMoreParams = route.path.split("/").length > 3; //contextDomain/lang/region

  return {
    regionParam: typeof regionParam === "string" ? regionParam : undefined,
    isRegionHome: !hasMoreParams,
    isAmericas: regionParam ? regionParam === "americas" : competitionStore.region?.slug === "americas",
    isSpain: regionParam ? regionParam === "espana" : competitionStore.region?.slug === "espana",
    isItaly: regionParam ? regionParam === "italia" : competitionStore.region?.slug === "italia",
    isBrazil: regionParam ? regionParam === "brazil" : competitionStore.region?.slug === "brazil",
    isWorldCup: regionParam ? regionParam === "world-cup" : competitionStore.region?.slug === "world-cup",
    isNations: regionParam ? regionParam === "kwc-nations" : competitionStore.region?.slug === "kwc-nations",
    isKokNations: regionParam
      ? regionParam === "king-of-kings"
      : competitionStore.region?.slug === "king-of-kings",
    regionTimeZone: currentRegions?.timeZone || "UTC",
  };
}
