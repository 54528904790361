// nuxt-ui/middleware/validationNewsGuard.ts

export default defineNuxtRouteMiddleware(to => {
  const { $i18n } = useNuxtApp();
  const locale = $i18n.locale;
  if (to.params && to.params.news) {
    if (
      (locale.value === "es" && to.params.news !== "actualidad") ||
      (locale.value === "en" && to.params.news !== "news") ||
      (locale.value === "it" && to.params.news !== "notizie") ||
      (locale.value === "de" && to.params.news !== "aktuelles") ||
      (locale.value === "fr" && to.params.news !== "actualites") ||
      (locale.value === "pt" && to.params.news !== "noticias")
    ) {
      throw createError({ statusCode: 404 });
    }
  }
});
