<!--
// nuxt-ui/components/pages/region-home/BannerNations.vue
-->
<script setup lang="ts">
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { isFutureDate } from "~/src/Shared/utils";

const { locale } = useI18n();
const localePath = useLocalePath();
const { isItaly } = useRegions();

const showBanner = ref(isFutureDate("2025-01-12T20:15:00.000Z"));

const bannerData = ref({
  linkLeft:
    isItaly && isFutureDate("2025-01-10T19:00:00.000Z")
      ? "https://www.ticketone.it/artist/kings-world-cup-nations-finals/kings-world-cup-nations-knockout-stage-3790569/"
      : `https://kingsleague.pro${localePath({ name: "region", params: { region: "kwc-nations" } })}`,
  linkLeftTarget: isItaly && isFutureDate("2025-01-10T19:00:00.000Z") ? "_blank" : "_self",
  imageBgLeft: "/img/nations/double-banner/bg-banner-nations-left.jpg",
  imageLeft:
    isItaly && isFutureDate("2025-01-10T19:00:00.000Z")
      ? `/img/nations/double-banner/banner-nations-left-${locale.value}.png`
      : "https://cms-es.kingsleague.pro/wp-content/uploads/2024/06/ITA-half-Follow-live-996x176-contenido-3.png",
  descriptionLeft: "KWC Nations",
  linkRight:
    "https://www.ticketone.it/artist/kings-world-cup-nations-finals/kings-world-cup-nations-final-3765195/?affiliate=KOS",
  linkRightTarget: "_blank",
  imageBgRight: "/img/nations/double-banner/bg-banner-nations-right.jpg",
  imageRight: `/img/nations/double-banner/banner-nations-right-${locale.value}.png`,
  descriptionRight: "KWC Nations Final tickets",
});

onMounted(() => {
  showBanner.value = isFutureDate("2025-01-12T20:15:00.000Z");
});
</script>

<template>
  <section v-if="showBanner">
    <div class="kql-double-banner-container">
      <component
        :is="bannerData.linkLeft !== '' ? 'a' : 'div'"
        :href="bannerData.linkLeft"
        :target="bannerData.linkLeftTarget !== '' ? bannerData.linkLeftTarget : '_self'"
        class="left-banner bigsm:!justify-end"
        :style="`background-image: url('${bannerData.imageBgLeft}')`"
      >
        <div class="double-banner-image">
          <img :src="bannerData.imageLeft" :alt="bannerData.descriptionLeft" />
        </div>
      </component>

      <component
        :is="bannerData.linkRight !== '' ? 'a' : 'div'"
        :href="bannerData.linkRight"
        :target="bannerData.linkRightTarget !== '' ? bannerData.linkRightTarget : '_self'"
        class="right-banner bigsm:!justify-start"
        :style="`background-image: url('${bannerData.imageBgRight}')`"
      >
        <div class="double-banner-image">
          <img :src="bannerData.imageRight" :alt="bannerData.descriptionRight" />
        </div>
      </component>
    </div>
  </section>
</template>

<style scoped>
/*.kql-banner-horizontal-container {
  @apply justify-center items-center max-w-full bg-cover bg-center md:max-h-[117px];
}

.full-banner-image {
  @apply flex justify-center items-center max-w-[1420px] px-2 h-full md:max-h-[117px];
}*/

.kql-double-banner-container {
  @apply max-w-full lg:max-h-[117px] overflow-hidden flex flex-col bigsm:flex-row items-center justify-center;
}

.left-banner,
.right-banner {
  @apply flex justify-center items-center w-full bigsm:w-1/2 bg-cover bg-center bigsm:max-h-[117px];
}

.double-banner-image {
  @apply flex items-center justify-center w-full px-2 bigsm:max-w-[720px] h-full bigsm:max-h-[117px];
}
</style>
